import React from "react";
import styles from "./styles.module.scss";
import { BASE_URL } from "../../../api";
import { IMAGES } from "../../../constants/assets";
import moment from "moment";
import ActionButtons from "components/buttons/actionButtons";
import { noDataLabels } from "constants/contants";

const DossierTable = ({
  tableHeading,
  tableData,
  deleteClick,
  hasDeleteAccess,
}) => {
  const handlerDownloadFile = (e, fileName) => {
    fetch(`${BASE_URL}/bucket/${fileName}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
    });
  };

  return (
    <div className={styles.tablehead}>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" className={styles.fileNameTh}>
              {tableHeading.th1}
            </th>
            <th scope="col" className={styles.uploadedByStyles}>
              {tableHeading.th2}
            </th>
            <th scope="col" className={styles.thirdDiv}>
              {tableHeading.th3}
            </th>
            <th scope="col" className={styles.actionHead}>
              {tableHeading.th5}
            </th>
          </tr>
        </thead>

        <tbody>
          {tableData?.map((item, index) => (
            <tr key={index}>
              <td>
                <div className={styles.documentName}>
                  <img src={IMAGES.FILE_PDF} alt="" className="me-1" />
                  <span>
                    {item?.fileName ? item?.fileName : ""}

                    <div className={styles.bottomStyles}>
                      {moment(tableData[0]?.createdAt).format("DD MMM YYYY")}
                    </div>
                  </span>
                </div>
              </td>
              <td className={styles.td2}>
                <div>{item?.client?.name ? item?.client?.name : ""}</div>
              </td>

              <td className={styles.td3}>
                {`
                  ${item?.generatedBy?.name ? item?.generatedBy?.name : ""}
                   ${
                     item?.generatedBy?.lastName
                       ? item?.generatedBy?.lastName
                       : ""
                   }
               `}
              </td>

              <td className={item?.isBlocked ? "" : styles.tableData}>
                {item?.isBlocked ? (
                  "In Progress"
                ) : (
                  <div
                    className={styles.tableLinkConatiner}
                    onClick={(e) => e.stopPropagation(e)}
                  >
                    <ActionButtons
                      generatedById={item?.generatedBy?._id}
                      viewAction={() => {
                        window.open(
                          `/docs/${item?.fileName}?fid=${
                            item?.clientId ? item?.clientId : item?.talentId
                          }&bid=${item?.uploadedBy?._id}`,
                          "_blank"
                        );
                      }}
                      DownloadAction={(e) =>
                        handlerDownloadFile(e, item?.fileName)
                      }
                      deleteAction={() => deleteClick(item?._id)}
                      accessControl={hasDeleteAccess}
                    />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {tableData?.length === 0 ? (
        <div className={styles.noDataContainer}>
          <h4 className={styles.dataNotFound}>{noDataLabels.dossierTable}</h4>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DossierTable;
