import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Formik } from "formik";
import FormInput from "../../../../../components/forms/formInput";
import { addEditApi } from "../../../../../api/commonApis";
import { APIS } from "../../../../../api/endpoints";
import { toast } from "react-toastify";
import ToastMessage from "../../../../../components/layout/toast";
import { talentLabUserSchema } from "../../../../../constants/formSchema";
import ImageUploader from "../../../../../components/imageUploader";
import { IMAGE_BASE_URL } from "../../../../../constants/contants";
import cloneDeep from "lodash/cloneDeep";
import LinkButton from "../../../../../components/buttons/linkButton";
import Checkbox from "../../../../../components/forms/checkbox";
import {
  ACCESS_PRIVILEGES,
  SUPER_ADMIN_ACCESS,
  ADMIN_ACCESS,
  FINANCE_MANAGER_ACCESS,
  CONSULTANT_ACCESS,
  ROLES_OPTIONS,
  CONSULTANT_ROLES_OPTIONS,
  RESEARCH_ASSOCIATE_ACCESS,
} from "../../../../../constants/contants";
import debounce from "lodash/debounce";
import PrimaryButton from "../../../../../components/buttons/primaryButton";
import { listApi } from "../../../../../api/commonApis";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { getState } from "redux/store";

const HIDE_PRIVILAGES = ["Organizations", "Talent", "Super Admin"];

const initialValues = {
  name: "",
  middleName: "",
  lastName: "",
  organization: "",
  // password: "",
  email: "",
  phoneNumber: "",
  talentLabRole: "",
  imageUrl: { preview: "", raw: "" },
  commonId: "",
  initialRoles: [
    // {
    //   module: "Overview",
    //   permission: {
    //     overviewOverView: ["read"],
    //   },
    // },
    // {
    //   module: "Search",
    //   permission: {
    //     SearchOverView: ["read"],
    //   },
    // },
    {
      module: "Talent",
      permission: {
        talentOverView: ["read"],
        work: ["read"],
        education: ["read"],
        documents: ["read"],
        compensation: ["read"],
        certsAndPublications: ["read"],
        compentencyMapping: ["read"],
        candidateDossier: ["read"],
        candidateHistory: ["read"],
      },
    },
    {
      module: "Organizations",
      permission: {
        organizationOverView: ["read"],
        contracts: ["read"],
        assignments: ["read"],
        billing: ["read"],
        clientHistory: ["read"],
        pointOfContact: ["read"],
      },
    },
    {
      module: "Assignment",
      permission: {
        assignmentOverView: ["read"],
        assignedCandidates: ["read"],
        addCandidates: ["read"],
        assignmentHistory: ["read"],
        activityLog: ["read"],
      },
    },
    {
      module: "Data Sets",
      permission: {
        userManagment: ["read"],
        functionalAreas: ["read"],
        industryTypes: ["read"],
        sectors: ["read"],
        skillsTags: ["read"],
        competencies: ["read"],
        educationTypes: ["read"],
      },
    },
  ],
  talentChecked: false,
  organizationChecked: false,
  assignmentChecked: false,
  dataSetChecked: false,
  // overViewChecked: false,
  // searchChecked: false,
};

const AddEdit = ({ addEditSaveHanlder, selectedData, backButtonHandler }) => {
  const loggedUser = getState()?.auth?.data?.talentLabRole;
  const [loading, setLoading] = useState(false);
  const [talentLabData, setTalentLabData] = useState({});
  // const [passwordVisible, setPasswordVisible] = useState(false);
  const [roleValue, setRoleValue] = useState();
  const [consultantRoles, setConsultantRoles] = useState(false);

  useEffect(() => {
    if (loggedUser === "Consultant") {
      setConsultantRoles(true);
    }
  }, []);
  /////////// Save Handler/////////////////////////////////

  const saveHanlder = (payload) => {
    setLoading(true);
    const data = new FormData();
    if (selectedData) {
      data.append("adminId", payload?._id);
    }
    data.append("name", payload?.name);
    if (payload?.middleName) {
      data.append("middleName", payload?.middleName);
    } else {
      data.append("middleName", "");
    }
    if (payload?.phoneNumber) {
      data.append("phoneNumber", payload?.phoneNumber);
    }

    if (HIDE_PRIVILAGES.includes(payload?.talentLabRole?.value)) {
      payload.initialRoles = [];
    }
    data.append("lastName", payload?.lastName);
    data.append("email", payload?.email);

    if (payload?.organization) {
      data.append("organization", payload?.organization);
    }
    data.append("talentLabRole", payload?.talentLabRole?.value);
    // data.append("password", payload?.password);
    data.append("roles", JSON.stringify(payload?.initialRoles));
    data.append("selfSignUp", true);
    data.append("admin_generated_login", true);

    if (payload?.commonId?.value) {
      data.append("commonId", payload?.commonId?.value);
    }

    if (payload?.imageUrl && payload?.imageUrl?.raw !== "") {
      data.append("image", payload?.imageUrl?.raw);
    }

    addEditApi(APIS.ADD_EDIT_ADMINS, data)
      .then((response) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={
              selectedData
                ? TOAST_MESSAGE.UPDATE_TALENT_USER
                : TOAST_MESSAGE.ADD_TALENT_USER
            }
          />
        );
        addEditSaveHanlder();
      })
      .finally(setLoading(false));
  };

  /////////////GOT DATA WHEN It WAS A EDIT CASE////////////////////

  useEffect(() => {
    if (selectedData) {
      const data = cloneDeep(selectedData);

      setRoleValue(data.talentLabRole);
      data.talentLabRole = {
        label: data.talentLabRole,
        value: data.talentLabRole,
      };

      data.initialRoles = data.roles;

      if (data && data?.imageUrl?.original) {
        data.imageUrl = {
          preview: IMAGE_BASE_URL + data?.imageUrl?.original,
          raw: "",
        };
      } else {
        data.imageUrl = {
          preview: "",
          raw: "",
        };
      }

      setTalentLabData(data);
    }
  }, []);

  ///////ACCESS CONTROL ALL HANDLER///////////////////

  /* When Single Single Check Box Will Checked by User */
  const SingleCheckedHandler = (
    values,
    currentValue,
    index,
    setFieldValue,
    permissionValue
  ) => {
    if (
      values?.initialRoles[index]?.permission?.[currentValue]?.includes(
        permissionValue
      )
    ) {
      if (permissionValue === "read") {
        if (
          values?.initialRoles?.[index]?.permission?.[currentValue].includes(
            "write"
          ) ||
          values?.initialRoles?.[index]?.permission?.[currentValue].includes(
            "delete"
          )
        ) {
          console.log("NOT ABLE TO CHANGE UNTIL DELETE AND WRITE HAS VALUE");
        } else {
          setFieldValue(
            `initialRoles[${index}].permission.${[currentValue]}`,
            values?.initialRoles?.[index]?.permission?.[currentValue]?.filter(
              (item) => item !== permissionValue
            )
          );
        }
      } else {
        setFieldValue(
          `initialRoles[${index}].permission.${[currentValue]}`,
          values?.initialRoles?.[index]?.permission?.[currentValue]?.filter(
            (item) => item !== permissionValue
          )
        );
      }
    } else {
      if (permissionValue === "write" || permissionValue === "delete") {
        if (
          values?.initialRoles?.[index]?.permission?.[currentValue].includes(
            "read"
          )
        ) {
          setFieldValue(`initialRoles[${index}].permission.${[currentValue]}`, [
            ...values?.initialRoles?.[index]?.permission?.[currentValue],
            permissionValue,
          ]);
        } else {
          setFieldValue(`initialRoles[${index}].permission.${[currentValue]}`, [
            ...values?.initialRoles?.[index]?.permission?.[currentValue],
            permissionValue,
            "read",
          ]);
        }
      } else {
        setFieldValue(`initialRoles[${index}].permission.${[currentValue]}`, [
          ...values?.initialRoles?.[index]?.permission?.[currentValue],
          permissionValue,
        ]);
      }
    }
  };

  /* When Bulk Checked By user  */

  const allCheckedHandler = (values, index, setFieldValue, check) => {
    if (values?.[check]) {
      const permission = values?.initialRoles?.[index]?.permission;
      const newObj = {};
      for (const key in permission) {
        newObj[key] = [];
      }

      setFieldValue(`initialRoles[${index}].permission`, newObj);
    } else {
      const permission = values?.initialRoles?.[index]?.permission;
      const newObj = {};
      for (const key in permission) {
        newObj[key] = ["read", "write", "delete"];
      }

      setFieldValue(`initialRoles[${index}].permission`, newObj);
    }
  };

  /* Use to Change the value of the Radio Button When CLiked on them */

  const bulkCheckHandler = (values, index, setFieldValue) => {
    // if (index === 0) {
    //   setFieldValue("overViewChecked", !values.overViewChecked);
    // }
    // if (index === 1) {
    //   setFieldValue("searchChecked", !values.searchChecked);
    // }
    if (index === 0) {
      setFieldValue("talentChecked", !values.talentChecked);
    }
    if (index === 1) {
      setFieldValue("organizationChecked", !values.organizationChecked);
    }
    if (index === 2) {
      setFieldValue("assignmentChecked", !values.assignmentChecked);
    }
    if (index === 3) {
      setFieldValue("dataSetChecked", !values.dataSetChecked);
    }
  };

  /* Use for default check in the radio Button */

  const bulkDefaultCheck = (values, index) => {
    const permission = values?.initialRoles?.[index]?.permission;
    for (const key in permission) {
      if (
        permission?.[key].includes("read") &&
        permission?.[key].includes("write") &&
        permission?.[key].includes("delete")
      ) {
      } else {
        return false;
      }
    }
    return true;
  };

  const talentRoleHandler = (data, setFieldValue) => {
    setFieldValue("talentLabRole", data);

    if (data.value === "Consultant") {
      setFieldValue("initialRoles", CONSULTANT_ACCESS);
    }
    if (data.value === "Admin") {
      setFieldValue("initialRoles", ADMIN_ACCESS);
    }
    if (data.value === "Finance Manager") {
      setFieldValue("initialRoles", FINANCE_MANAGER_ACCESS);
    }
    if (data.value === "Super Admin") {
      setFieldValue("initialRoles", SUPER_ADMIN_ACCESS);
    }
    if (data.value === "Research Associate") {
      setFieldValue("initialRoles", RESEARCH_ASSOCIATE_ACCESS);
    }
  };

  const showdropDown = (value) => {
    if (value === "Talent" || value === "Organizations") {
      return true;
    }
    return false;
  };

  const LoadOptions = (inputValue, resolve) => {
    let apiName;
    if (roleValue === "Organizations") {
      apiName = APIS.LIST_CLIENT;
    } else {
      apiName = APIS.LIST_TALENTS;
    }

    listApi(apiName, { search: inputValue }).then((result) => {
      const filter = result?.data?.data?.map((item) => ({
        label: `${item?.firstName ? item?.firstName : ""} ${
          item?.middleName ? item?.middleName : ""
        } ${item?.lastName ? item?.lastName : ""}  ${
          item?.name ? item?.name : ""
        } `,
        value: item?._id,
      }));

      resolve(filter);
    });
  };

  const loadOptions = (inputValue, resolve) => LoadOptions(inputValue, resolve);

  const loadListOption = debounce(loadOptions, 300);

  //////////////// RETURN/////////////////////////////////////////

  return (
    <div className={`tab-content ${styles.tabcontent}`} id="v-pills-tabContent">
      <Formik
        onSubmit={saveHanlder}
        initialValues={selectedData ? talentLabData : initialValues}
        validationSchema={talentLabUserSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          dirty,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className={styles.middleCard}>
              <div className={styles.topheading}>
                <h3 className={styles.h3heading}>
                  {selectedData ? "Edit" : "Add"} TalentLab User{" "}
                </h3>
                <div className="d-flex">
                  <LinkButton onClick={backButtonHandler}>Back</LinkButton>

                  <PrimaryButton
                    type="submit"
                    label={"Save"}
                    disabled={loading || !dirty}
                  />
                </div>
              </div>
              <hr className={styles.topCardDivider} />
              <ImageUploader
                setFieldValue={setFieldValue}
                title="Admin Image"
                handleImageChange={(e) => {
                  if (e.target.files.length) {
                    setFieldValue("imageUrl", {
                      preview: URL.createObjectURL(e.target.files[0]),
                      raw: e.target.files[0],
                    });
                  }
                }}
                image={values?.imageUrl}
              />
              <FormInput
                label="First Name"
                {...getFieldProps("name")}
                error={touched?.name && errors?.name}
              />
              <FormInput
                label="Middle Name"
                {...getFieldProps("middleName")}
                error={touched?.middleName && errors?.middleName}
              />
              <FormInput
                label="Last Name"
                {...getFieldProps("lastName")}
                error={touched?.lastName && errors?.lastName}
              />
              <FormInput
                label="Email Address"
                {...getFieldProps("email")}
                error={touched?.email && errors?.email}
              />
              <FormInput
                label="Organizational Role/Designation"
                {...getFieldProps("organization")}
                error={touched?.organization && errors?.organization}
              />
              <FormInput
                maxLength={20}
                label="Mobile Number"
                {...getFieldProps("phoneNumber")}
                error={touched?.phoneNumber && errors?.phoneNumber}
              />
              <FormInput
                label="TalentLab Role"
                type="select"
                value={values?.talentLabRole}
                options={
                  consultantRoles ? CONSULTANT_ROLES_OPTIONS : ROLES_OPTIONS
                }
                onChange={(data) => {
                  talentRoleHandler(data, setFieldValue);
                  setRoleValue(data?.value);
                  setFieldValue("commonId", "");
                }}
                error={touched?.talentLabRole && errors?.talentLabRole}
              />

              {showdropDown(values?.talentLabRole?.value) && (
                <FormInput
                  defaultOptions
                  label={
                    values?.talentLabRole?.value === "Talent"
                      ? "Choose talent"
                      : "Choose organization"
                  }
                  key={`${roleValue}`}
                  type="async-select"
                  value={values?.commonId}
                  loadOptions={loadListOption}
                  onChange={(data) => {
                    setFieldValue("commonId", data);
                  }}
                />
              )}

              {/* {!selectedData && (
                <div style={{ position: "relative" }}>
                  <FormInput
                    label="Password"
                    type={passwordVisible ? "text" : "password"}
                    {...getFieldProps("password")}
                    error={touched?.password && errors?.password}
                  />
                  <img
                    alt="password"
                    className={styles.eyeViewHide}
                    src={passwordVisible ? IMAGES.EYESLASH : IMAGES.EYE}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                </div>
              )} */}
            </div>

            {!HIDE_PRIVILAGES.includes(values?.talentLabRole?.value) && (
              <>
                <div className={styles.accessHeader}> Access Privileges</div>
                <hr className={styles.bottomDivider} />
                <div className={styles.accessCard}>
                  {ACCESS_PRIVILEGES.map((data, index) => {
                    return (
                      <>
                        <div className={styles.mainHeadding}>
                          <h3 className={styles.accessHeading}>
                            {data?.heading}
                          </h3>
                          <Checkbox
                            smallSize
                            id={data?.heading}
                            checked={bulkDefaultCheck(values, index)}
                            onChange={() => {
                              bulkCheckHandler(values, index, setFieldValue);
                              allCheckedHandler(
                                values,
                                index,
                                setFieldValue,
                                data?.ObjectCheck
                              );
                            }}
                          />
                          <h3 className={styles.selectAllStyles}>Select All</h3>
                        </div>

                        <div className={styles.tableContainer}>
                          <div className={styles.mainHeading}></div>
                          <div className={styles.viewStyles}>View</div>
                          <div className={styles.addEditStyles}>Add/Edit</div>
                          <div className={styles.addEditStyles}>
                            {data?.ObjectCheck === "assignmentChecked"
                              ? "Archive"
                              : "Delete"}
                          </div>
                        </div>
                        {data?.objectData?.map((items) => (
                          <div className={styles.tableContainer}>
                            <div className={styles.mainHeading}>
                              {items?.label}
                            </div>
                            <div className={styles.viewStyles}>
                              <Checkbox
                                smallSize
                                id={items?.label}
                                checked={values?.initialRoles?.[
                                  index
                                ]?.permission?.[items?.value]?.includes("read")}
                                onChange={() => {
                                  SingleCheckedHandler(
                                    values,
                                    items?.value,
                                    index,
                                    setFieldValue,
                                    "read"
                                  );
                                }}
                              />
                            </div>
                            {items?.hideAddEdit !== true && (
                              <div className={styles.addEditStyles}>
                                <Checkbox
                                  smallSize
                                  id={items?.value}
                                  checked={values?.initialRoles?.[
                                    index
                                  ]?.permission?.[items?.value]?.includes(
                                    "write"
                                  )}
                                  onChange={() => {
                                    SingleCheckedHandler(
                                      values,
                                      items?.value,
                                      index,
                                      setFieldValue,
                                      "write"
                                    );
                                  }}
                                />
                              </div>
                            )}

                            {items?.hideDelete !== true &&
                              items?.isEnable === true && (
                                <div className={styles.addEditStyles}>
                                  <Checkbox
                                    smallSize
                                    id={items?.code}
                                    checked={
                                      data?.ObjectCheck === "assignmentChecked"
                                        ? values?.initialRoles?.[
                                            index
                                          ]?.permission?.[
                                            items?.value
                                          ]?.includes("archive")
                                        : values?.initialRoles?.[
                                            index
                                          ]?.permission?.[
                                            items?.value
                                          ]?.includes("delete")
                                    }
                                    onChange={() => {
                                      SingleCheckedHandler(
                                        values,
                                        items?.value,
                                        index,
                                        setFieldValue,
                                        data?.ObjectCheck ===
                                          "assignmentChecked"
                                          ? "archive"
                                          : "delete"
                                      );
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        ))}
                        <hr className={styles.bottomDivider} />
                      </>
                    );
                  })}
                </div>
              </>
            )}
            <hr className={styles.bottomDivider} />
            <div className={styles.topheading}>
              <h3 className={styles.h3heading}></h3>
              <div className={styles.middleCard}>
                <div className="d-flex">
                  <PrimaryButton
                    type="submit"
                    label={"Save"}
                    disabled={loading || !dirty}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddEdit;
