import React from "react";
import styles from "./styles.module.scss";
import CommentsSection from "../../../../components/commentSection";
import StatusToggle from "./statusToggle";
import { COMMENT_TYPE } from "constants/contants";
import { getState } from "redux/store";

const Settings = ({ id, tab, refresh, hasWriteAccess, hasCommentAccess }) => {
  const user = getState()?.auth?.data;
  const style = getState()?.auth?.data.selfSignUp
    ? { height: "510px", overflowY: "scroll" }
    : {};
  return (
    <div style={style} className={`tab-content ${styles.rightdetails}`}>
      {hasWriteAccess &&
        tab !== "history" &&
        tab !== "tl-shortcuts" &&
        tab !== "change-password" && (
          <StatusToggle activeTabType={2} refresh={refresh} />
        )}

      {tab !== "history" &&
      tab !== "tl-shortcuts" &&
      tab !== "change-password" &&
      hasCommentAccess ? (
        <CommentsSection
          shouldFilter={user.talentLabRole === "Organizations"}
          hasWriteAccess={hasWriteAccess}
          type={COMMENT_TYPE.ORGANIZATION}
          id={id}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Settings;
