import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";
import LoginFooter from "components/loginFooter";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ForgetPasswordSchema } from "constants/formSchema";
import { addEditApi } from "api/commonApis";
import { APIS } from "api/endpoints";
import { TOAST_MESSAGE } from "constants/toastMessage";
import ToastMessage from "components/layout/toast";
import { toast } from "react-toastify";

///INITIALVALUES

const defaultValues = {
  email: "",
};

const ForgetPasswordExt = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);

  const onSubmit = (payload, { resetForm }) => {
    setLoading(true);

    addEditApi(APIS.FORGOT_PASSWORD, { email: payload.email }).then(
      (result) => {
        toast.success(
          <ToastMessage title={"Success"} message={TOAST_MESSAGE.SEND_LINK} />
        );

        setLoading(false);
        setSuccess(true);
        resetForm(defaultValues);
      }
    );
    setLoading(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.middleBox}>
        <div>
          <img src={IMAGES.LOGO_SVG} />
        </div>
        <div>
          <div className={styles.headingContainer}>
            <h1 className={styles.headingtext}>Forgot Password</h1>
            <p className={styles.headingPara}>
              &nbsp;
              {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat. */}
            </p>
          </div>

          <Formik
            onSubmit={onSubmit}
            initialValues={defaultValues}
            validationSchema={ForgetPasswordSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                  <div className={styles.inputContainer}>
                    <div className={styles.labelContainer}>
                      <span className={styles.errorMsg}>
                        &nbsp;
                        {(errors.email && touched.email && errors.email) || ""}
                      </span>
                    </div>
                    <input
                      name="email"
                      className={styles.inputStyles}
                      type="text"
                      placeholder="Enter your registered email address  "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.email}
                    />
                  </div>

                  <div className={styles.labelContainer}>
                    <span className={styles.errorMsg}>&nbsp;</span>
                  </div>
                  <button
                    type="submit"
                    onMouseOver={() => setButtonHover(true)}
                    onMouseLeave={() => setButtonHover(false)}
                    className={styles.button}
                    disabled={loading}
                  >
                    <span>{loading ? "Loading..." : "Request reset link"}</span>
                    <img
                      src={
                        buttonHover ? IMAGES?.BLACK_ARROW : IMAGES.RIGHT_ARROW
                      }
                      className={styles.arrowStyles}
                    />
                  </button>
                  <div className={styles.forgetContainer}>
                    <img
                      src={IMAGES.BACK_TO_LOGIN}
                      className={styles.backArrow}
                    />
                    <span
                      className={styles.forgetText}
                      onClick={() => navigate(-1)}
                    >
                      Back to login
                    </span>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div style={{ marginTop: "60px" }}>
          <LoginFooter />
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordExt;
