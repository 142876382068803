import React, { useEffect, useState } from "react";
import { addEditApi, listApi } from "../../api/commonApis";
import { APIS } from "../../api/endpoints";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import CommentItem from "./commentItem";
import CommentInput from "./commentInput";
import { convertCommentToHtml, filterAdminIds } from "../../utils/helper";
import { useParams, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { IMAGE_BASE_URL } from "../../constants/contants";
import { ristrictAccess, sidePanelAccess } from "../../utils/helper";
import { getState } from "redux/store";

const CommentsSection = ({
  fromDashboard,
  setRefresh,
  mentionData,
  type,
  id,
  showOnly = 3,
  renderAsTab,
  title,
  allowComment = true,
  hasWriteAccess,
  check,
  shouldFilter = false,
}) => {
  const [comments, setComments] = useState([]);
  const [success, setSuccess] = useState(false);
  const [refreshApi, setRefreshApi] = useState(false);
  const [page, setPage] = useState(0);
  const [limit] = useState(10);
  const user = useSelector((state) => state?.auth?.data);
  const [params, setParams] = useSearchParams();
  const [count, setCount] = useState(0);
  const [inputfocus, setInputFocus] = useState(false);
  const [isSidePanel, setIsSidePanel] = useState(false);
  const [org_team_members, set_org_team_members] = useState([]);
  const [org_team_members_id, set_org_team_members_id] = useState([]);

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  const commentApiHit = () => {
    const user = getState()?.auth?.data;
    if (id && type) {
      let team_ids;
      listApi(APIS.LIST_ADMIN, {
        commonId: user?.commonId,
      }).then((result) => {
        const team_id = result?.data?.data.map((t) => t?._id);
        team_ids = team_id;
        console.log(team_id, "team_id");
        set_org_team_members_id(team_id);
      });

      listApi(APIS.LIST_COMMENTS, {
        type,
        id,
        skip: user.selfSignUp ? 0 : limit * page,
        limit: user.selfSignUp ? 999 : limit,
      }).then((res) => {
        const user_id = `${user._id}`;

        const mapped_comments = res?.data?.data.map((a) => {
          //this is done because orgs have a ext assignment panel. The normal mention link takes them to assignment page, which is to be blocked for orgs

          let modified_comment;
          if (user.talentLabRole === "Organizations") {
            if (a?.text.includes("/assignments/overview/")) {
              const regex = /\/assignments\/overview\/(\w+)/g;
              modified_comment = a.text.replace(regex, (match, _id) => {
                console.log(`match: ${match}, id: ${_id}`);
                return `/clients/overview/${id}?tab=assignments-ext&activeId=${_id}`;
              });
            } else {
              modified_comment = a?.text;
            }
          } else {
            modified_comment = a?.text;
          }

          return {
            ...a,
            text: modified_comment,
            should_render: {
              user_inclusion: a.text.includes(user_id),
              common_id: a.addedBy.commonId === user?.commonId,
              common_id_inclusions: a.text.includes(user?.commonId),
              team_id_inclusion: team_ids.some((id) => a.text.includes(id)),
            },
          };
        });

        console.log(mapped_comments, { mapped_comments });

        const only_coments_associated_with_the_user = mapped_comments.filter(
          (c) => {
            // console.log(res?.data?.data, "Comments");
            // console.log({
            //   a: c.text.includes(user_id),
            //   b: c.addedBy.commonId === user?.commonId,
            //   x: c.text.includes(user?.commonId),
            //   z: team_ids.some((id) => c.text.includes(id)),
            // });
            return (
              c.should_render.user_inclusion ||
              c.should_render.common_id ||
              c.should_render.common_id_inclusions ||
              c.should_render.team_id_inclusion
            );
          }
        );

        if (renderAsTab) {
          // setComments([...comments, ...res?.data?.data]);
          if (shouldFilter) {
            setComments(only_coments_associated_with_the_user);
          } else {
            setComments([...comments, ...res?.data?.data]);
          }
        } else {
          setComments(res?.data?.data);
          if (shouldFilter) {
            setComments(only_coments_associated_with_the_user);
          } else {
            setComments(res?.data?.data);
          }
        }
        setCount(res?.data?.count);

        setPage(page + 1);
      });
    }
  };

  useEffect(() => {
    commentApiHit();
  }, [id, type, success]);

  const addComment = (newComment, mentionsIds) => {
    addEditApi(APIS.ADD_EDIT_COMMENTS, {
      id,
      type,
      text: convertCommentToHtml(newComment),
      mentions: filterAdminIds(mentionsIds),
    }).then(() => {
      setPage(0);
      if (renderAsTab) {
        setComments([]);
      }

      setSuccess((s) => !s);
    });
  };

  const addReply = (newComment, commentId, mentionsIds) => {
    addEditApi(APIS.ADD_EDIT_COMMENT_REPLY, {
      id,
      type,
      commentId,
      text: convertCommentToHtml(newComment),
      mentions: filterAdminIds(mentionsIds),
    }).then(() => {
      //Here comment becom zero
      setPage(0);
      if (renderAsTab) {
        setComments([]);
      }

      setSuccess((s) => !s);
      setRefresh((s) => !s);
    });
  };

  const wrapTab = (section) => (
    <div className={styles.tabContainer}>
      <div className={styles.tabHeading}>
        <h2 className={styles.headingText}>{title}</h2>
      </div>
      {/* <div className={styles.noHistroyFooter}>No history found</div> */}

      {section}
    </div>
  );

  const Section = (
    <div
      className={`d-flex tl-comment-section ${
        fromDashboard
          ? ""
          : ristrictAccess() || !isSidePanel
          ? styles.hideContainer
          : hasWriteAccess
          ? styles.container
          : styles.Upcontainer
      } ${renderAsTab ? styles.tabView : ""} `}
    >
      <div className={styles.comments}>
        {/* hasCommentWriteAccess */}
        {!fromDashboard && allowComment && (
          <>
            {!renderAsTab && (
              <>
                <div
                  className={`d-flex  ${
                    ristrictAccess() || !isSidePanel
                      ? styles.ristrictSearch
                      : styles.commentsearch
                  }`}
                >
                  {/* align-items-center */}
                  <div
                    className={
                      ristrictAccess() || !isSidePanel
                        ? styles.ristrictComment
                        : styles.commenttext
                    }
                  >
                    {user?.imageUrl?.original ? (
                      <img
                        src={IMAGE_BASE_URL + user?.imageUrl?.original}
                        className={styles.adminThumImage}
                      />
                    ) : (
                      <h5
                        className={styles.adminInitail}
                      >{`${user?.name?.[0]}${user?.lastName?.[0]}`}</h5>
                    )}
                    <div
                      className={styles.userContainer}
                    >{`${user?.name} ${user.lastName}`}</div>
                  </div>

                  <CommentInput
                    setInputFocus={setInputFocus}
                    onAddComment={addComment}
                    renderAsTab={renderAsTab}
                  />
                </div>
                <hr
                  className={inputfocus ? styles.activeDivider : styles.divider}
                />
              </>
            )}
          </>
        )}

        {renderAsTab ? (
          <>
            {comments?.length === 0 ? (
              <div className={styles.commentFooter}>No comments yet.</div>
            ) : (
              <InfiniteScroll
                dataLength={page * 10}
                next={commentApiHit}
                // hasMore
                hasMore={comments.length === count ? false : true}
                loader={<div className={styles.commentFooter}>Loading...</div>}
                height={500}
                endMessage={
                  <div className={styles.commentFooter}>No more comments.</div>
                }
              >
                {comments?.map((comment, index) => (
                  <CommentItem
                    renderAsTab={renderAsTab}
                    key={index}
                    comment={comment}
                    onReply={addReply}
                    allowComment={allowComment}
                    setPage={setPage}
                    setComments={setComments}
                    setSuccess={setSuccess}
                    // hasCommentWriteAccess={hasCommentWriteAccess}
                    // hasCommentDeleteAccess={hasCommentDeleteAccess}
                  />
                ))}
              </InfiniteScroll>
            )}
          </>
        ) : (
          <>
            {(mentionData || comments)?.map((comment, index) =>
              user.talentLabRole == "Organizations" ? (
                <CommentItem
                  renderAsTab={renderAsTab}
                  mentionData
                  key={index}
                  comment={comment}
                  onReply={addReply}
                  allowComment={allowComment}
                  setPage={setPage}
                  setComments={setComments}
                  setSuccess={setSuccess}
                  // hasCommentWriteAccess={hasCommentWriteAccess}
                  // hasCommentDeleteAccess={hasCommentDeleteAccess}
                />
              ) : (
                index < showOnly && (
                  <CommentItem
                    renderAsTab={renderAsTab}
                    mentionData
                    key={index}
                    comment={comment}
                    onReply={addReply}
                    allowComment={allowComment}
                    setPage={setPage}
                    setComments={setComments}
                    setSuccess={setSuccess}
                    // hasCommentWriteAccess={hasCommentWriteAccess}
                    // hasCommentDeleteAccess={hasCommentDeleteAccess}
                  />
                )
              )
            )}

            {!fromDashboard ? (
              comments?.length ? (
                !ristrictAccess() && (
                  <div className={styles.seearchrive}>
                    <h4
                      className={styles.archiveHover}
                      onClick={() => setParams({ tab: "history" })}
                    >
                      See Comment Archive
                    </h4>
                  </div>
                )
              ) : (
                <div className={styles.commentFooter}>No comments yet.</div>
              )
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );

  return renderAsTab ? wrapTab(Section) : Section;
};

export default CommentsSection;
