import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  Outlet,
  useLocation,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTES } from "../../../router/routes";
import { IMAGES } from "../../../constants/assets";
import SideMenu from "../../../components/layout/sideMenu";
import { hasPermission, ristrictAccess, sidePanelAccess } from "utils/helper";
import { MODULES, PERMISSIONS, SUB_MODULES } from "constants/accessControl";
import { getState } from "redux/store";

const AddEditTalent = (props) => {
  //////// SIDE MENU AT THE TIME OF EDIT CASE/////

  const TAB_MENU = [
    {
      label: "Overview",
      href: ROUTES.PROFILE,
      tabName: "overview",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.TALENT_OVERVIEW,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Work",
      href: "work",
      tabName: "work",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.WORK,
        PERMISSIONS.READ
      ),
    },

    {
      label: "Education",
      href: "education",
      tabName: "education",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.EDUCATION,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Compensation",
      href: "compensation",
      tabName: "compensation",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.COMPENSATION,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Documents",
      href: "documents",
      tabName: "documents",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.DOCUMENTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Certifications & Publications",
      href: "certs-publications",
      tabName: "certs",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CERT_PUBLICATIONS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Competency Mapping",
      href: "compentancy-mapping",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.COMPENTENCY_MAPPING,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Candidate Dossier",
      href: "candidate-dossier",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CANDIDATE_DOSSIER,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Candidate History",
      tabName: "history",
      hasAccess: hasPermission(
        MODULES.TALENT,
        SUB_MODULES.CANDIDATE_HISTORY,
        PERMISSIONS.READ
      ),
    },
  ];

  //// SIDE MENU AT TIME OF RISTRICT////////

  const RISTRICT_TAB_MENU = [
    {
      label: "Overview",
      href: ROUTES.PROFILE,
      tabName: "overview",
      hasAccess: true,
    },
    {
      label: "Work",
      href: "work",
      tabName: "work",
      hasAccess: true,
    },
    {
      label: "Education",
      href: "education",
      tabName: "education",
      hasAccess: true,
    },
    {
      label: "Compensation",
      href: "compensation",
      tabName: "compensation",
      hasAccess: true,
    },
    {
      label: "Documents",
      href: "documents",
      tabName: "documents",
      hasAccess: true,
    },
    {
      label: "Certifications & Publications",
      href: "certs-publications",
      tabName: "certs",
      hasAccess: true,
    },
  ];
  const { pathname } = useLocation();
  const { id } = useParams();
  const [params] = useSearchParams();
  const source = params.get("source");
  const navigate = useNavigate();
  const [isSidePanel, setIsSidePanel] = useState(false);

  const getLink = (menu) => {
    if (source && menu.tabName) {
      return `/talent/overview/${id}?tab=${menu.tabName}`;
    } else if (source) {
      return `${menu.href}${id ? `/${id}` : ""}?source=overview`;
    } else {
      return `${menu.href}${id ? `/${id}` : ""}`;
    }
  };

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  // check if user is authorized to access this route
  useEffect(() => {
    const current_user = getState()?.auth?.data;

    if (current_user.talentLabRole === "Talent") {
      if (current_user.commonId !== id) {
        navigate("/");
      }
    }

    if (current_user.talentLabRole === "Organizations") {
      navigate("/");
    }
  }, []);

  return (
    <section
      className={
        ristrictAccess() || !isSidePanel
          ? styles.ristrictContainer
          : styles.overview
      }
    >
      <div className="container">
        <div className="row">
          {!source && (
            <div className="mb-4 col-md-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className={styles.heading}>
                  <h2>{id ? "Edit Talent" : "Add a new talent"} </h2>
                </div>
                {id && (
                  <div
                    className={`d-flex ${styles.viewprofile}`}
                    onClick={() =>
                      navigate(`${ROUTES.TALENT}${ROUTES.OVERVIEW}/${id}`)
                    }
                  >
                    <h4>View Profile</h4>
                    <img
                      alt="Profile"
                      src={IMAGES.LINK_ICON}
                      className="ms-1"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="col-md-12">
            <div className="d-flex align-items-start">
              {id && (
                <SideMenu
                  items={ristrictAccess() ? RISTRICT_TAB_MENU : TAB_MENU}
                  onClick={(menu) => navigate(getLink(menu))}
                  isActive={(menu) => pathname?.includes(menu.href)}
                />
              )}

              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddEditTalent;
