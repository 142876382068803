import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../../constants/assets";
import { listApi, addEditApi } from "../../../../../api/commonApis";
import { APIS } from "../../../../../api/endpoints";
import CustomTable from "../components/customTable";
import Pagination from "../../../../../components/table/pagination";
import debounce from "lodash/debounce";
import ConfirmationModal from "../../../../../components/modal/confirmationModal";
import { DELETE_MODAL_CONFIRMATION } from "../../../../../constants/contants";
import { toast } from "react-toastify";
import ToastMessage from "../../../../../components/layout/toast";
import { hasPermission } from "../../../../../utils/helper";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../../constants/accessControl";
import PrimaryButton from "../../../../../components/buttons/primaryButton";
import UserIcon from "../../../../../components/icons/userIcon";
import BasicConfirmationModal from "../../../../../components/modal/basicConfirmationModal";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./index.css";
import { getState } from "redux/store";
import SendInvite from "components/invitation/SendInvite";

const Overview = ({ addEditHanlder, RowSelected, role = "admins" }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [adminList, setAdminList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [selectedData, setSelectedData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [check, setCheck] = useState("");
  const open = Boolean(anchorEl);
  const [filter, setFilter] = useState(" ");
  const [transferId, setTransferId] = useState("");
  const [accessControl, setAccessControl] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [current_user, set_current_user] = useState();

  const [params, setParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const tab = params?.get?.("tab");

  useEffect(() => {
    const user = getState()?.auth?.data;
    set_current_user(user);
    console.log(user, "user");

    setAccessControl(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.WRITE
      )
    );
  }, []);

  /*Filter Handler */

  const filterHandler = () => {
    if (filter === " ") {
      setFilter(2);
    }
    if (filter === 2) {
      setFilter(1);
    }
    if (filter === 1) {
      setFilter(2);
    }
  };

  /////////// INDUSTRY TYPE LISTING FOR TABLE (API)/////////////

  useEffect(() => {
    let payload = {
      talentLabRole: role,
    };
    setLoading(true);

    if (selectedData?._id) {
      payload.skipAdminId = selectedData?._id;
    }

    if (filter !== " ") {
      payload.sort = filter;
    }

    // listApi(APIS.LIST_ADMIN, {
    //   search,
    //   skip: limitPerPage * page,
    //   limit: limitPerPage,
    //   ...payload,
    // })
    //   .then((result) => {
    //     const org_data = result?.data?.data.filter((o) => {
    //       return o.commonId == id;
    //     });

    //     setAdminList(org_data);
    //     setCount(org_data.length);
    //   })
    //   .finally(() => setLoading(false));

    listApi(APIS.FETCH_INVITE_DETAILS, {
      id: id,
    }).then((result) => {
      const filtered_invites = result.data.filter((inv) => {
        if (inv?.conversion_id) {
          return inv?.conversion_id?.isDeleted == false;
        }

        return inv;
      });
      setAdminList(filtered_invites);
      setCount(
        result.data.filter((inv) => {
          return (
            inv?.invitation_accepted && inv?.conversion_id?.isDeleted == false
          );
        }).length
      );
    });
  }, [id]);

  ///Tto get Data of the selected Row when Clicked on the ThreeDot Button/////

  const handleClick = (event, data) => {
    setSelectedData(data?.conversion_id);
    RowSelected(data);
    setAnchorEl(event.currentTarget);
  };

  //////////to open and Close the Confirmation for DELETE ADMIN //////////

  const openConfirmHanlder = (check) => {
    if (check === "delete") {
      setCheck(1);
    }
    if (check === "block") {
      setCheck(2);
    }

    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setCheck(""); /// Reset Check When Confirmations Is Closed
    setSelectedData(); /// Reset SELECTED DATA When Confirmations Is Closed
    setOpenConfirmation(false); /// Close Confirmations
  };

  ////////// HANDLER DELETE AND BLOCK ADMIN (API)  ///////

  const confirmationHandler = () => {
    if (check === 1) {
      addEditApi(APIS.DELETE_DOCUMENT_DATA, {
        id: selectedData?._id,
        type: 21,
      }).then((result) => {
        setRefresh(!refresh);
        handleCloseConfirmation();
        toast.success(
          <ToastMessage title={"Success"} message={TOAST_MESSAGE.DELETE_USER} />
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    }
    if (check === 2) {
      addEditApi(APIS.BLOCK_DATA, {
        id: selectedData?._id,
        type: 3,
        status: !selectedData?.isBlocked,
      }).then((result) => {
        setRefresh(!refresh);
        handleCloseConfirmation();
        toast.success(
          <ToastMessage
            title={"Success"}
            message={
              selectedData?.isBlocked
                ? TOAST_MESSAGE.UNBLOCKED_USER
                : TOAST_MESSAGE.BLOCKED_USER
            }
          />
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    }
  };

  //////////// HANLDER FOR ADMIN TABLE////////////
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onPageChange = (pageNo) => {
    setPage(pageNo);
  };

  //////////// HANDLER FOR SEARCHING ///////////////
  const SearchedData = (event) => {
    setSearch(event?.target?.value);
  };
  const searchHandler = debounce(SearchedData, 300);

  /////////////HANDLER FOR OPEN AND CLOSE A PASSWODR CHANGE MODAL///////

  const passowrdModalHandler = () => {
    setOpenModal(true);
  };

  const closePassModalHandler = () => {
    setSelectedData();
    setOpenModal(false);
  };

  const passwordClickHanlder = () => {
    addEditApi(APIS.FORGOT_PASSWORD, { email: selectedData?.email }).then(
      () => {
        toast.success(
          <ToastMessage title={"Success"} message={TOAST_MESSAGE.RESET} />
        );
        closePassModalHandler();
      }
    );
  };

  //////CONTENT FOR CHANGE PASSOWRD MODAL////////////
  const modalContent = (selectedData) => {
    return (
      <>
        You are about to reset
        <b>
          {` ${selectedData?.name} 
         ${selectedData?.middleName && selectedData?.middleName}
        ${selectedData?.lastName} `}
        </b>
        password. This will send email instructions to reset password.
      </>
    );
  };

  ////////////////////////////////////////////////////////

  return (
    <div className={`tab-content ${styles.tabcontent}`} id="v-pills-tabContent">
      <div className={styles.edithead}>
        <div className={styles.topheading}>
          <h3 className={styles.h3heading}>
            Team Members{" "}
            <span style={{ fontSize: "10px", color: "gray" }}>
              <i>(utilised {count} out of 5 team member seats)</i>
            </span>
          </h3>

          {count < 5 &&
            (current_user?.organization_role === "Organization Admin" ||
              current_user?.organization_role === "CxO / Co-Founder" ||
              current_user?.organization_role === "CHRO" ||
              current_user?.organization_role === "CFO" ||
              current_user?.talentLabRole != "Organizations") && (
              <div className="d-flex">
                {/* <PrimaryButton
                  leftImageClassName={styles.addTalentUser}
                  leftIcon={UserIcon}
                  label="Add team member"
                  onClick={(event) => addEditHanlder(event, true)}
                /> */}
                <SendInvite organisation={{ _id: id }}></SendInvite>
              </div>
            )}
        </div>
      </div>
      <hr className={styles.cardDivider} />

      {/* <div className={styles.middleCard}>
        <div className={styles.searchInputContainer}>
          <input
            type="text"
            className={styles.searchInputDataSets}
            placeholder="Search"
            onChange={searchHandler}
          />

          <img
            alt="search"
            src={IMAGES.SEARCH_ICON}
            className={styles.searchIcon}
          />
        </div>
      </div> */}

      {adminList.length <= 0 ? (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <div className="tw-p-4 tw-rounded-full tw-text-gray-500 tw-bg-gray-200 tw-my-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="tw-w-8"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="M12.5 4.5a2.5 2.5 0 1 1-5 0a2.5 2.5 0 0 1 5 0Zm5 .5a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm-13 2a2 2 0 1 0 0-4a2 2 0 0 0 0 4ZM6 9.25C6 8.56 6.56 8 7.25 8h5.5c.69 0 1.25.56 1.25 1.25V14a4 4 0 0 1-8 0V9.25Zm-1 0c0-.463.14-.892.379-1.25H3.25C2.56 8 2 8.56 2 9.25V13a3 3 0 0 0 3.404 2.973A4.983 4.983 0 0 1 5 14V9.25ZM15 14c0 .7-.144 1.368-.404 1.973A3 3 0 0 0 18 13V9.25C18 8.56 17.44 8 16.75 8h-2.129c.24.358.379.787.379 1.25V14Z"
              ></path>
            </svg>
          </div>

          <p className="tw-text-xs">
            No team members added yet. Click on the button above to add a team
            member.
          </p>
        </div>
      ) : (
        <CustomTable
          passowrdModalHandler={passowrdModalHandler}
          addEditHanlder={addEditHanlder}
          open={open}
          handleClose={handleClose}
          handleClick={handleClick}
          anchorEl={anchorEl}
          list={adminList}
          loading={loading}
          openConfirmHanlder={openConfirmHanlder}
          status={selectedData?.isBlocked}
          filterHandler={filterHandler}
          filter={filter}
        />
      )}
      <div className={styles.paginationContiner}>
        {/* <Pagination
          totalCount={count}
          currentPage={page}
          limitPerPage={limitPerPage}
          onPageChange={onPageChange}
          setLimitPerPage={setLimitPerPage}
        /> */}
        <ConfirmationModal
          list={adminList}
          title={DELETE_MODAL_CONFIRMATION}
          open={openConfirmation}
          closeModal={handleCloseConfirmation}
          onChangeHanlder={confirmationHandler}
          data={`${selectedData?.name} ${selectedData?.lastName} `}
          status={selectedData?.isBlocked}
          block={check === 2 ? true : false}
          blockUser={true}
          deleteTalentLabUser={false}
          deleteTransferTitle={`Select a user to transfer ${selectedData?.name}'s records`}
          setTransferId={setTransferId}
          transferId={transferId}
        />

        <BasicConfirmationModal
          closeModal={closePassModalHandler}
          openConfirmation={openModal}
          passwordChange
          data={modalContent(selectedData)}
          buttonLabel="Reset Password"
          cloneConfirmHandler={passwordClickHanlder}
        />
      </div>
    </div>
  );
};

export default Overview;
