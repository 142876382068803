export const ROUTES = {
  APP: "/",
  LOGIN: "/login",
  CLIENT_LOGIN: "/login/client-portal",
  TALENT_LOGIN: "/login/candidate-login",
  CLIENT_SIGNUP: "/login/client-sign-up",
  TALENT_SIGNUP: "/login/candidate-sign-up",
  // FORGET_PASSWORD_EXT: "/forget-password-ext",
  // RESET_PASSWORD_EXT: "/reset-password-ext/:id",

  OVERVIEW: "/overview",
  SEARCH: "/search",
  TALENT: "/talent",
  CLIENTS: "/clients",
  ASSIGNMENTS: "/assignments",
  ASSIGNMENTS_OVERVIEW: "overview/:id",
  USER_PROFILE: "/profile",
  PROFILE_DETAILS: "/profile/details",
  PROFILE_TALENTLAB: "/profile/talentlab",

  CLIENT_DETAIL: "detail",
  CLIENT_OVERVIEW: "overview/:id",
  BILLING: "billing/:id",
  CONTRACT: "contracts/:id",

  ADD_ASSIGNMENT: "add-assignment",
  EDIT_ASSIGNMENT: "edit-assignment/:id",
  TALENT_OVERVIEW: "overview/:id",
  PROFILE: "profile",
  WORK: "work/:id",
  EDUCATION: "education/:id",
  COMPENSATION: "compensation/:id",
  DOCUMENTS: "documents/:id",
  CERT_PUBLICATIONS: "certs-publications/:id",
  CANDIDATE_DOSSIER: "candidate-dossier/:id",
  COMPENTANCY_MAPPING: "compentancy-mapping/:id",
  ADD: "add",
  EDIT: "edit/:id",
  ID: ":id",

  DOC_VIEWER: "/docs/:id",
  FORGET_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:id/:type",
  SET_PASSWORD: "/set-password/:id/:type",

  ERROR_PAGE: "/error",
  INVITATION: "/invitation/:id",

  TERMS: "/terms-and-condition",
  PRIVACY: "/privacy-policy",
};
