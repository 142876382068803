import React, { useState } from "react";
import Overview from "./overview";
import AddEdit from "./components/addEdit";
const TeamMembers = ({ role = "orgs" }) => {
  const [activeOverview, setActiveOverview] = useState(true);
  const [selectedData, setSelectedData] = useState("");

  const RowSelected = (rowData) => {
    setSelectedData(rowData?.conversion_id);
  };

  const addEditHanlder = (event, add) => {
    if (add) {
      setSelectedData(""); //to make the selected Data empty when clicked on the add talent Lab
    }
    setActiveOverview(false);
  };

  /* Handler When CLick on the save Button inside the Add Edit Page*/
  const addEditSaveHanlder = () => {
    setActiveOverview(true);
  };

  /*Hanlder when Back from the Edit page to table page*/
  const backButtonHandler = () => {
    setActiveOverview(true);
  };
  return (
    <>
      {activeOverview ? (
        <Overview
          role={role}
          addEditHanlder={addEditHanlder}
          RowSelected={RowSelected}
        />
      ) : (
        <AddEdit
          addEditSaveHanlder={addEditSaveHanlder}
          selectedData={selectedData}
          backButtonHandler={backButtonHandler}
        />
      )}
    </>
  );
};

export default TeamMembers;
