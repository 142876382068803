import React from "react";
import styles from "./styles.module.scss";
import StatusToggles from "./rightSideToggles";
import CommentSection from "../../../../components/commentSection";
import { COMMENT_TYPE } from "../../../../constants/contants";
import { getState } from "redux/store";

const Settings = ({
  talentData,
  refreshdata,
  tab,
  // hasCommentWriteAccess,
  // hasCommentDeleteAccess,
  hasWriteAccess,
  hasCommentAccess,
}) => {
  const user = getState()?.auth?.data;
  const style = getState()?.auth?.data.selfSignUp
    ? { height: "510px", overflowY: "scroll" }
    : {};

  return (
    <div style={style} className={`tab-content ${styles.rightdetails}`}>
      {hasWriteAccess && (
        <StatusToggles
          activeTabType={1}
          talentData={talentData}
          refreshdata={refreshdata}
        />
      )}

      {tab !== "history" &&
        tab !== "tl-shortcuts" &&
        tab !== "change-password" &&
        hasCommentAccess && (
          <CommentSection
            shouldFilter={user.talentLabRole === "Talent" && user.selfSignUp}
            hasWriteAccess={hasWriteAccess}
            type={COMMENT_TYPE.TALENT}
            id={talentData?._id}
            // hasCommentWriteAccess={hasCommentWriteAccess}
            // hasCommentDeleteAccess={hasCommentDeleteAccess}
          />
        )}
    </div>
  );
};

export default Settings;
