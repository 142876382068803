import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import "index.css";
import { IMAGES } from "../../../../../../constants/assets";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import { IMAGE_BASE_URL } from "../../../../../../constants/contants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { hasPermission } from "../../../../../../utils/helper";
import { useSelector } from "react-redux";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../../../constants/accessControl";
import ChangePassowrd from "../../../../../../components/icons/changePassword";
import { getState } from "redux/store";
import { addEditApi } from "api/commonApis";
import { APIS } from "api";
import ToastMessage from "components/layout/toast";
import { toast } from "react-toastify";

const ITEM_HEIGHT = 48;

const CustomTable = ({
  addEditHanlder,
  open,
  handleClose,
  handleClick,
  anchorEl,
  list,
  openConfirmHanlder,
  status,
  filterHandler,
  filter,
  passowrdModalHandler,
}) => {
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [hasEditAccess, setHasEditAccess] = useState(false);
  const isSuperAdmin = useSelector((state) => state?.auth?.data?.superAdmin);
  const [current_user, set_current_user] = useState();

  const [accessed_user, set_accessed_user] = useState({});

  const [menu_accessed, set_menu_accessed] = useState();

  const resend_invite = () => {
    try {
      addEditApi(APIS.RESEND_INVITE, {
        ...menu_accessed,
      }).then((result) => {
        toast.success(
          <ToastMessage
            title={"Invite sent successfully"}
            message={`Account setup instructions are sent to their email.`}
          />
        );
      });
    } catch (error) {
      console.log(error);
      toast.error(
        <ToastMessage
          title={"Error"}
          message={`There was an error sending invite`}
        />
      );
    }
  };

  const delete_invite = () => {
    try {
      addEditApi(APIS.DELETE_INVITE, {
        _id: menu_accessed._id,
      }).then((result) => {
        toast.success(
          <ToastMessage
            title={"Invite deleted successfully. "}
            message={`The page will refresh automatically`}
          />
        );

        window.location.reload();
      });
    } catch (error) {
      console.log(error);
      toast.error(
        <ToastMessage
          title={"Error"}
          message={`There was an error deleting the invite`}
        />
      );
    }
  };

  useEffect(() => {
    setHasEditAccess(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.WRITE
      )
    );
    setHasDeleteAccess(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.DELETE
      )
    );

    const user = getState()?.auth?.data;
    set_current_user(user);
  }, []);

  return (
    <>
      <div className={`${styles.tableHeading} ${styles.tableHeadingFont}`}>
        <div onClick={filterHandler} className={styles.tableHeadingDataName}>
          Name
          {filter === 2 ? (
            <ArrowDownwardIcon className={styles.arrowStyle} />
          ) : (
            <ArrowUpwardIcon className={styles.arrowStyle} />
          )}
        </div>
        <div className={styles.tableHeadingSpace}></div>
        <div className={styles.tableHeadingDataSlug}>Role</div>
        <div className={styles.tableHeadingSpace}></div>
        {/* <div className={styles.tableHeadingDataCreated}>Last Active</div> */}
        <div
          className={
            styles.tableHeadingDataStatus +
            " tw-flex tw-items-center tw-space-x-2 "
          }
        >
          <span>Status</span>

          <div className="tw-text-slate-200 tw-group tw-relative">
            <img
              src="/images/new-images/noun-information-2119887.svg"
              alt="tooltip"
            />
            <div className="tw-hidden group-hover:tw-block tw-rounded-md tw-shadow-lg tw-absolute tw-w-max tw-bottom-0 tw-right-full tw-px-6 tw-py-2 tw-bg-white tw-text-black tw-text-[12px]">
              Shows if the user is blocked or active.
            </div>
          </div>
        </div>
        {(current_user?.organization_role === "Organization Admin" ||
          current_user?.organization_role === "CxO / Co-Founder" ||
          current_user?.organization_role === "CHRO" ||
          current_user?.organization_role === "CFO" ||
          current_user?.talentLabRole !== "Organizations") && (
          <div className={styles.tableHeadingDataActions}>Actions</div>
        )}
      </div>
      <hr className={styles.tableDivider} />
      <div className={styles.tableDataOuterContainer}>
        {list.map((data) => (
          <>
            <div
              className={`${styles.tableDataContainer} ${styles.TableDataFontStyle}`}
            >
              <div className={`${styles.tableDataNameSize} `}>
                <div className={styles.commenttext}>
                  {data?.conversion_id?.imageUrl?.original ? (
                    <img
                      alt="head"
                      src={
                        IMAGE_BASE_URL + data?.conversion_id?.imageUrl?.original
                      }
                      className={styles.adminThumImage}
                    />
                  ) : (
                    <h5 className={styles.adminInitail}>{`${
                      data?.conversion_id
                        ? data?.conversion_id?.name?.[0]
                        : data?.first_name[0]
                    }${
                      data?.conversion_id
                        ? data?.conversion_id?.lastName?.[0]
                        : data?.last_name[0]
                    }`}</h5>
                  )}
                </div>
                <div>
                  <div className={styles.nameStyles}>{`${
                    data?.conversion_id
                      ? data?.conversion_id?.name
                      : data?.first_name
                  } ${
                    data?.conversion_id
                      ? data?.conversion_id?.lastName
                      : data?.last_name
                  }`}</div>
                  <div className={styles.emailStyle}>
                    {data?.conversion_id?.email
                      ? data?.conversion_id?.email
                      : data?.email}
                  </div>
                </div>
              </div>
              <div className={styles.tableDataSpace}></div>
              <div className={`${styles.tableDataSlugSize} `}>
                {data?.conversion_id?.organization_role || data?.role || ""}
              </div>
              <div className={styles.tableDataSpace}></div>
              {/* <div className={`${styles.tableDataCreatedSize} `}>
                {moment(data?.lastSeen)?.fromNow?.()}
              </div> */}
              <div className={`${styles.tableDataStatusSize} `}>
                <div
                  className={
                    !data?.invitation_accepted
                      ? "tw-w-[10px] tw-h-[10px] tw-rounded-full tw-bg-indigo-300 tw-mr-[10px]"
                      : data?.conversion_id?.isBlocked
                      ? styles.blackDot
                      : styles.greenDot
                  }
                ></div>
                {!data?.invitation_accepted
                  ? "Invitation Pending"
                  : data?.conversion_id?.isBlocked
                  ? "Blocked"
                  : "Active"}
              </div>

              {(current_user?.organization_role === "Organization Admin" ||
                current_user?.organization_role === "CxO / Co-Founder" ||
                current_user?.organization_role === "CHRO" ||
                current_user?.organization_role === "CFO" ||
                current_user?.talentLabRole !== "Organizations") && (
                <div className={styles.tableDataActionsSize}>
                  {
                    <Tooltip title="Menu">
                      <IconButton
                        aria-label="menu"
                        onClick={(event) => {
                          handleClick(event, data);
                          set_accessed_user(data?.conversion_id);
                          set_menu_accessed(data);
                        }}
                      >
                        <MoreVertIcon
                          sx={{ transform: "rotate(90deg)" }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  }
                </div>
              )}
            </div>
            <hr className={styles.cardDivider} />
          </>
        ))}
      </div>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        sx={{
          "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: "#e7e9fe",
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {menu_accessed?.invitation_accepted && (
          <>
            {
              <MenuItem
                className={styles.menuItemStyle}
                onClick={(handleClose, addEditHanlder)}
              >
                <img
                  alt="edit"
                  src={
                    hasEditAccess
                      ? IMAGES.TABLE_MENU_EDIT
                      : IMAGES.TABLE_DISABLE_EDIT
                  }
                  className={styles.iconstyle}
                />
                Edit
              </MenuItem>
            }

            {accessed_user?._id !== current_user?._id && (
              <MenuItem
                className={styles.menuItemStyle}
                onClick={() => {
                  handleClose();
                  openConfirmHanlder("block");
                }}
              >
                <img
                  alt="deleet"
                  src={
                    status ? IMAGES.TABLE_MENU_UNBLOCK : IMAGES.TABLE_MENU_BLOCK
                  }
                  className={styles.iconstyle}
                />
                {status ? "Unblock" : "Block"}
              </MenuItem>
            )}
            {accessed_user?._id !== current_user?._id && (
              <MenuItem
                className={styles.menuItemStyle}
                onClick={() => {
                  handleClose();
                  openConfirmHanlder("delete");
                }}
              >
                <img
                  alt="delete"
                  src={IMAGES.TABLE_MENU_DELETE}
                  className={styles.iconstyle}
                />
                Delete
              </MenuItem>
            )}
            {accessed_user?._id !== current_user?._id && (
              <MenuItem
                className={styles.menuItemStyle}
                onClick={() => {
                  handleClose();
                  passowrdModalHandler("changePassword");
                }}
              >
                <div style={{ marginRight: "8px" }}>
                  <ChangePassowrd />
                </div>
                Reset Password
              </MenuItem>
            )}
          </>
        )}

        {!menu_accessed?.invitation_accepted && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              delete_invite();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="tw-mr-[8px]"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
            >
              <path d="M2 2a2 2 0 00-2 2v8.01A2 2 0 002 14h5.5a.5.5 0 000-1H2a1 1 0 01-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 001 0V4a2 2 0 00-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 011-1h12a1 1 0 011 1v.217l-7 4.2z"></path>
              <path d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-4.854-1.354a.5.5 0 000 .708l.647.646-.647.646a.5.5 0 00.708.708l.646-.647.646.647a.5.5 0 00.708-.708l-.647-.646.647-.646a.5.5 0 00-.708-.708l-.646.647-.646-.647a.5.5 0 00-.708 0"></path>
            </svg>
            Delete Invitation
          </MenuItem>
        )}

        {!menu_accessed?.invitation_accepted && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              resend_invite();
            }}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="tw-mr-[8px]"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path d="M13 19H5a2 2 0 01-2-2V7a2 2 0 012-2h14a2 2 0 012 2v6"></path>
              <path d="M3 7l9 6 9-6M16 22l5-5M21 21.5V17h-4.5"></path>
            </svg>
            Resend Invite
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
export default CustomTable;
