import React from "react";
import "./index.css";
import { IMAGES } from "constants/assets";
import LoginFooter from "components/loginFooter";

const PrivacyPolicy = () => {
  return (
    <div
      className="tw-bg-[#0c2659] tw-flex tw-flex-col tw-justify-between tw-py-16 tw-bg-no-repeat tw-w-full tw-h-screen tw-bg-center tw-bg-cover"
      style={{
        backgroundImage: `url("/images/background.png")`,
      }}
    >
      <div className=" tw-flex tw-items-center tw-justify-center">
        <img width={200} src={IMAGES.LOGO_SVG} />
      </div>
      <div className="tw-w-8/12 tw-h-[70%] tw-p-16 tw-mx-auto tw-bg-white tw-shadow-md tw-rounded-md">
        <div className="tw-pr-4 tw-h-full tw-overflow-hidden tw-overflow-y-scroll no-scroll">
          <h2 className="tw-text-black !tw-m-0">
            <strong>Privacy Policy</strong>
          </h2>
          <p className="tw-text-[#2e2e2e]">
            Updated 22<sup>nd</sup> Nov 2024
          </p>
          <p className="tw-text-[#2e2e2e]">Welcome to TalentLab!</p>
          <p className="tw-text-[#2e2e2e]">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p className="tw-text-[#2e2e2e]">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
          <h3 className="tw-text-black tw-text-lg">
            <strong>Collecting and Using Your Personal Data</strong>
          </h3>
          <p className="tw-text-[#2e2e2e]">
            <strong>Types of Data Collected</strong>
          </p>
          <p className="tw-text-[#2e2e2e]">
            <strong>Personal Data of Talents</strong>
          </p>
          <p className="tw-text-[#2e2e2e]">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul className="tw-text-[#2e2e2e] tw-text-xs">
            <li>Name (required)</li>
            <li>Phone number</li>
            <li>Email (required)</li>
            <li>Photo (Avatar)</li>
            <li>Location details limited to country, state & city</li>
            <li>Social profile links</li>
            <li>Gender</li>
            <li>
              Employment history including designation served, name of
              organization and employment period
            </li>
            <li>
              Education history including name of degree, university name and
              degree period
            </li>
            <li>Certificates and publications details</li>
            <li>
              Documents such as resumes, offer letters, compensation documents,
              resignation acceptance letters
            </li>
            <li>
              Compensation history including fixed compensation, variable
              bonuses and ESOPs
            </li>
          </ul>
          <p className="tw-text-[#2e2e2e]">Personal Data of Organizations</p>
          <ul className="tw-text-[#2e2e2e] tw-text-xs">
            <li>Organization Name (required)</li>
            <li>Legal Name</li>
            <li>Organization Logo</li>
            <li>
              Point of contacts/ team members with their Name, Email (required)
            </li>
            <li>Location details limited to country, state & city</li>
            <li>Social profile links</li>
            <li>
              Other documents such as contracts and invoices between TalentLab
              and the organization, organization’s GST certificate,
              organization’s PAN
            </li>
            <li>
              Billing information including name, email, designation, and mobile
              number for the billing representative; Organization’s GSTN and PAN
              number; Complete billing address.
            </li>
          </ul>
          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Device Information:
          </h3>
          <p className="tw-text-[#2e2e2e]">
            Device information is collected automatically when using the
            Service.
          </p>
          <p className="tw-text-[#2e2e2e]">
            Device information may include information such as Your Device’s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that You visit, the time and date
            of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <p className="tw-text-[#2e2e2e]">
            When You access the Service by or through a any device, We may
            collect certain information automatically, including, but not
            limited to, the type of device You use, Your device unique ID, the
            IP address of Your device, Your operating system, the type of
            Internet browser You use, unique device identifiers and other
            diagnostic data.
          </p>

          <p className="tw-text-[#2e2e2e]">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a any
            device.
          </p>

          <p className="tw-text-[#2e2e2e]">
            The data collected will be used for the purpose of facilitating
            talent hiring services and is not going to be sold to 3rd parties.
          </p>

          <p className="tw-text-[#2e2e2e]">
            Further, the TalentLab guarantees that the data obtained by them is
            availed through ethical and legal means and protects its
            affiliates/vendors through this guarantee.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Web Browser Cookies:
          </h3>

          <p className="tw-text-[#2e2e2e]">
            Our Site may use "cookies" to enhance User experience. User's web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. User may
            choose to set their web browser to refuse cookies, or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Sharing of your information:
          </h3>

          <p className="tw-text-[#2e2e2e]">
            We may share Your personal information in the following situations:
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Third-Party Services
          </h3>

          <p className="tw-text-[#2e2e2e]">
            We may share Your personal information in the following situations:
            Users may be able to access other third-party services/websites
            through the Site, for example by clicking on links to those
            Third-Party Services from within the Site (LinkedIn, Twitter). We
            are not responsible for the privacy policies and/or practices of
            these Third-Party Services, and you are responsible for reading and
            understanding those Third-Party Services’ privacy policies.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Information Shared with Our Service Providers and Sub Processors
          </h3>

          <p className="tw-text-[#2e2e2e]">
            Users consent and acknowledge that we may share your information
            with third parties who provide services to us. These third parties
            are authorized to use your Personal Information only as necessary to
            provide these services to us. These services may include the
            provision of (i) email services to send application related
            communications, (ii) providing cloud computing infrastructure. The
            list of Third party Service providers are:
          </p>

          <table className="border tw-table tw-table-auto tw-text-[#2e2e2e] tw-mb-4">
            <tbody>
              <tr className="tw-p-1 tw-border">
                <td className="tw-p-1 tw-border">
                  <p className="!tw-mb-0 tw-text-[#2e2e2e]">
                    <strong>Name</strong> <strong></strong>
                  </p>
                </td>
                <td className="tw-p-1 tw-border">
                  <p className="!tw-mb-0 tw-text-[#2e2e2e]">
                    <strong>Purpose</strong> <strong></strong>
                  </p>
                </td>
              </tr>
              <tr className="tw-p-1 tw-border">
                <td className="tw-p-1 tw-border">
                  <p className="!tw-mb-0 tw-text-[#2e2e2e]">Google App</p>
                </td>
                <td className="tw-p-1 tw-border">
                  <p className="!tw-mb-0 tw-text-[#2e2e2e]">
                    Google meet and Google Calendar shall be used for scheduling
                    purposes. This shall depend upon the preferences set by the
                    user
                  </p>
                </td>
              </tr>
              <tr className="tw-p-1 tw-border">
                <td className="tw-p-1 tw-border">
                  <p className="!tw-mb-0 tw-text-[#2e2e2e]">Digital Ocean</p>
                </td>
                <td className="tw-p-1 tw-border">
                  <p className="!tw-mb-0 tw-text-[#2e2e2e]">
                    For hosting TalentLab Applications
                  </p>
                </td>
              </tr>
              <tr className="tw-p-1 tw-border">
                <td className="tw-p-1 tw-border">
                  <p className="!tw-mb-0 tw-text-[#2e2e2e]">SendGrid</p>
                </td>
                <td className="tw-p-1 tw-border">
                  <p className="!tw-mb-0 tw-text-[#2e2e2e]">
                    For email communication
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Information Disclosed for Our Protection and the Protection of
            Others
          </h3>

          <p className="tw-text-[#2e2e2e]">
            TalentLab may be required to disclose Personal Information in
            response to lawful requests by public authorities, including to meet
            national security or law enforcement requirements. TalentLab also
            reserves the right to access, read, preserve, and disclose any
            information as TalentLab reasonably believe is necessary to (i)
            satisfy any applicable law, regulation, legal process or
            governmental request (ii) enforce this Privacy Policy, including
            investigation of potential violations hereof, (iii) detect, prevent,
            or otherwise address fraud, security, or technical issues; (iv)
            respond to user support requests; or (v) protect our rights,
            property, or safety. This includes exchanging information with other
            companies and organizations for fraud protection and spam/malware
            prevention.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Data Retention
          </h3>

          <p className="tw-text-[#2e2e2e]">
            TalentLab will retain data it processes on behalf of its users only
            for as long as required to provide the Service to its Customers and
            as necessary to comply with its legal obligations, resolve disputes
            and enforce its agreements. The data in TalentLab is backed up for
            system continuity purposes and each backup file may be stored for 30
            days.
          </p>

          <p className="tw-text-[#2e2e2e]">
            After a deletion request by the user, data related to that user will
            be permanently deleted. This process cannot be reversed, and data
            will be permanently deleted. In cases, some data will not be deleted
            and shall be kept in an anonymized manner.
          </p>

          <p className="tw-text-[#2e2e2e]">
            User gives consent and acknowledge that TalentLab collects and
            retains metadata and statistical information concerning the use of
            the service which are not subject to the deletion procedures in this
            policy and may be retained by TalentLab for no more than required to
            conduct its business. Some data may be retained also on our
            third-party service providers’ servers in accordance with their
            retention policies. You will not be identifiable from this retained
            metadata or statistical information.
          </p>

          <p className="tw-text-[#2e2e2e]">
            Anonymized aggregated data may be retained by TalentLab for as long
            it is required to provide its services.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Data Security and Storage Information
          </h3>

          <p className="tw-text-[#2e2e2e]">
            The data we collect is hosted on a Cloud Server located in India
            which provides advanced security features and is compliant with ISO
            27001 and other privacy and security standards. TalentLab
            headquarters is based in India from where we provide user support
            services.
          </p>

          <p className="tw-text-[#2e2e2e]">
            TalentLab limits access to Databases and data storages to the
            limited appointed personals and vendor by TalentLab.
          </p>

          <p className="tw-text-[#2e2e2e]">
            TalentLab takes steps to ensure that its staff who have access to
            personal data are honest, reliable, competent, and periodically
            properly trained. TalentLab shall act in accordance with its
            policies to promptly notify User in the event that any personal data
            processed by TalentLab on behalf of User is lost, stolen, or where
            there has been any unauthorized access to it subject to applicable
            law and instructions from any agency or authority.
          </p>

          <p className="tw-text-[#2e2e2e]">
            The measures taken to ensure the security and confidentiality of the
            collected data are all website traffic routed through HTTPS layer
            with SSL, password encryption, role-based access controls, server
            level firewalls, all documents are signed with encrypted key and are
            only accessible with appropriate access, API rate limits,
            token-based authorization and regular security audits.
          </p>

          <p className="tw-text-[#2e2e2e]">
            The company or its affiliates/vendors will not be liable in any way
            for a data leak, data theft, malware attacks, hacking attempts, or
            any form of data breaches etc.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">Advertising</h3>

          <p className="tw-text-[#2e2e2e]">
            Advertisements appearing on our site may be delivered to Users by
            advertising partners, who may set cookies. These cookies allow the
            ad server to recognize your computer each time they send you an
            online advertisement to compile non-personal identification
            information about you or others who use your computer. This
            information allows ad networks to, among other things, deliver
            targeted advertisements that they believe will be of most interest
            to you. This privacy policy does not cover the use of cookies by any
            advertisers.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Changes To This Privacy Policy
          </h3>

          <p className="tw-text-[#2e2e2e]">
            TalentLab has the discretion to update this privacy policy at any
            time. We encourage Users to frequently check this page for any
            changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of modifications.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Your Acceptance Of These Terms
          </h3>

          <p className="tw-text-[#2e2e2e]">
            By using this Site, you signify your acceptance of this policy. If
            you do not agree to this policy, please do not use our Site. Your
            continued use of the Site following the posting of changes to this
            policy will be deemed your acceptance of those changes.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Changing Your Information Or Closing Your Account
          </h3>

          <p className="tw-text-[#2e2e2e]">
            Upon request TalentLab will provide you with information about
            whether we hold any of your personal information. You are
            responsible for maintaining the accuracy of the information you
            submit to us, such as your contact information. You may access,
            correct, or request deletion of your personal information by making
            updates to that information or by contacting us through your online
            account.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Reasonable Security Practices As Per Information Technology Act,
            2000 And Its Rules
          </h3>

          <p className="tw-text-[#2e2e2e]">
            We have implemented reasonable security practices as per Rule 8 of
            the Information Technology (Reasonable security practices and
            procedures and sensitive personal data or information) Rules, 2011
            of Information Technology Act, 2000 amended through Information
            Technology Amendment Act, 2008.
          </p>

          <h3 className="tw-font-bold tw-text-black tw-text-lg">
            Grievance Redressal
          </h3>

          <p className="tw-text-[#2e2e2e]">
            If you have any questions or grievances regarding the privacy
            statement, practices of the site, or any other transaction issue,
            please contact our grievance officer on …………………. The details of the
            Grievance Officer are as follows:
            <br /> Name: <br />
            Email: <br />
            Address:
            <br />
          </p>
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-items-center">
        <LoginFooter margin="0px"></LoginFooter>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
