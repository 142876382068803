import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../../constants/assets";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import { IMAGE_BASE_URL } from "../../../../../constants/contants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { hasPermission } from "../../../../../utils/helper";
import { useSelector } from "react-redux";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../../constants/accessControl";
import ChangePassowrd from "../../../../../components/icons/changePassword";
import Members from "./members";
import "./index.css";
import SendInvite from "components/invitation/SendInvite";
import InvitationDetails from "components/invitation/InvitationDetails";
import InvitationHandler from "components/invitation/InvitationHandler";
import DeleteOrg from "components/deleteOrganization/deleteOrg";

const ITEM_HEIGHT = 48;

const CustomTable = ({
  addEditHanlder,
  open,
  handleClose,
  handleClick,
  anchorEl,
  list,
  openConfirmHanlder,
  status,
  filterHandler,
  filter,
  passowrdModalHandler,
}) => {
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [hasEditAccess, setHasEditAccess] = useState(false);
  const isSuperAdmin = useSelector((state) => state?.auth?.data?.superAdmin);

  useEffect(() => {
    setHasEditAccess(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.WRITE
      )
    );
    setHasDeleteAccess(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.DELETE
      )
    );
  }, []);

  return (
    <>
      <div
        className={`${styles.tableHeading} ${styles.tableHeadingFont} tw-flex tw-items-center tw-gap-4`}
      >
        <div className={`!tw-w-[5%]`}></div>
        <div onClick={filterHandler} className={` !tw-w-[25%]`}>
          Name
          {filter === 2 ? (
            <ArrowDownwardIcon className={styles.arrowStyle} />
          ) : (
            <ArrowUpwardIcon className={styles.arrowStyle} />
          )}
        </div>

        <div className={`tw-text-center !tw-w-[15%]`}>Seats Used</div>

        {/* <div className={styles.tableHeadingDataCreated}>Last Active</div> */}
        <div className={`  !tw-w-1/4`}>Point of Contact</div>
        <div className={`!tw-w-[25%]`}>Team Management</div>

        {/* <div className={styles.tableHeadingDataActions}>Actions</div> */}
      </div>
      <hr className={styles.tableDivider} />
      <div className={styles.tableDataOuterContainer}>
        {list?.map((data) => (
          <div key={data?._id}>
            <div className={`tw-flex tw-items-center tw-gap-4`}>
              <div className="tw-w-[5%]">
                <DeleteOrg org_name={data?.name} id={data?._id}></DeleteOrg>
              </div>
              <div className={`tw-w-[25%] tw-flex tw-items-center tw-text-xs`}>
                <div className={styles.commenttext}>
                  {data?.imageUrl?.original ? (
                    <img
                      alt="head"
                      src={IMAGE_BASE_URL + data?.imageUrl?.original}
                      className={styles.adminThumImage}
                    />
                  ) : (
                    <h5
                      className={styles.adminInitail}
                    >{`${data?.name?.[0]}`}</h5>
                  )}
                </div>
                <div>
                  <div className={styles.nameStyles}>{`${data?.name}`}</div>
                </div>
              </div>

              <div className={`tw-w-[15%] tw-text-center tw-text-xs`}>
                <Members id={data?._id}></Members>
              </div>

              <div className={` tw-w-1/4`}>
                <p style={{ margin: 0, color: "black", fontSize: "10px" }}>
                  {data?.contactPerson}
                </p>
                <p style={{ margin: 0, color: "gray", fontSize: "10px" }}>
                  <a href={`mailto:${data?.contactPersonEmail}`}>
                    {data?.contactPersonEmail}
                  </a>
                </p>
                <p style={{ margin: 0, color: "gray", fontSize: "10px" }}>
                  <a href={`tel:${data?.contactPersonPhoneNumber}`}>
                    {data?.contactPersonPhoneNumber}
                  </a>
                </p>
              </div>

              <div className={` tw-w-[25%]`}>
                {/* <button
                  
                  className="lg:hover:tw-bg-[#011fc5] tw-text-xs lg:tw-bg-[#f4f4f4] tw-shadow-sm lg:tw-text-black flux lg:hover:tw-text-white tw-px-3 tw-py-2 tw-rounded-md "
                >
                  Send an invite
                </button> */}
                <InvitationHandler organisation={data}></InvitationHandler>
              </div>
            </div>
            <hr className={styles.cardDivider} />
          </div>
        ))}
      </div>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        sx={{
          "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: "#e7e9fe",
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {hasEditAccess && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={(handleClose, addEditHanlder)}
          >
            <img
              alt="edit"
              src={
                hasEditAccess
                  ? IMAGES.TABLE_MENU_EDIT
                  : IMAGES.TABLE_DISABLE_EDIT
              }
              className={styles.iconstyle}
            />
            Edit
          </MenuItem>
        )}

        {hasEditAccess && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              openConfirmHanlder("block");
            }}
          >
            <img
              alt="deleet"
              src={status ? IMAGES.TABLE_MENU_UNBLOCK : IMAGES.TABLE_MENU_BLOCK}
              className={styles.iconstyle}
            />
            {status ? "Unblock" : "Block"}
          </MenuItem>
        )}
        {hasDeleteAccess && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              openConfirmHanlder("delete");
            }}
          >
            <img
              alt="delete"
              src={IMAGES.TABLE_MENU_DELETE}
              className={styles.iconstyle}
            />
            Delete
          </MenuItem>
        )}

        {isSuperAdmin && (
          <MenuItem
            className={styles.menuItemStyle}
            onClick={() => {
              handleClose();
              passowrdModalHandler("changePassword");
            }}
          >
            <div style={{ marginRight: "8px" }}>
              <ChangePassowrd />
            </div>
            Reset Password
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
export default CustomTable;
