import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { APIS } from "api/endpoints";
import { listApi } from "api/commonApis";
import Overview from "./overview";
import AssignmentPage from "./assignmentPage";
import { ROLES } from "constants/contants";
import { useSelector } from "react-redux";
import { getState } from "redux/store";

const AssingnmentsExt = ({ headData }) => {
  const userRole = useSelector((state) => state?.auth?.data?.talentLabRole);
  const [tableData, setTableData] = useState([]);
  const [searchParams] = useSearchParams();
  const [isAssignment, setIsAssignment] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { id } = useParams();
  const activeId = searchParams.get("activeId");

  useEffect(() => {
    if (activeId) {
      setIsAssignment(true);
    }
  }, [activeId]);

  useEffect(() => {
    let apiKey;
    if (userRole === ROLES.TALENT) {
      apiKey = "talentId";
    } else if (userRole === ROLES.ORGANIZATIONS) {
      apiKey = "clientId";
    }

    listApi(APIS.LIST_ASSIGNMENTS_ORG_TALNET, { [apiKey]: id }).then(
      (result) => {
        setTableData(result?.data);
      }
    );
  }, []);

  const linkHandler = (id) => {
    setIsAssignment(true);
    setSelectedId(id);
  };

  return (
    <>
      {isAssignment ? (
        <AssignmentPage assignmentId={activeId ? activeId : selectedId} />
      ) : (
        <Overview
          headData={headData}
          tableData={tableData}
          onClick={linkHandler}
        />
      )}
    </>
  );
};
export default AssingnmentsExt;
