import React from "react";
import styles from "./styles.module.scss";
import { MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IMAGE_BASE_URL } from "constants/contants";
import SearchAbleInput from "components/filters/seacrhAbleInput";

const DeleteUserSection = ({
  deleteTransferTitle,
  transferId,
  setTransferId,
  singlOuterList,
  search,
  setSearch,
  list,
  searchByFields,
}) => {
  return (
    <>
      <div className={styles.titleCss}>{deleteTransferTitle}</div>

      <Select
        variant="standard"
        className={styles.transferSelect}
        value={transferId}
        displayEmpty
        onChange={(e) => {
          setTransferId(e?.target?.value);
        }}
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={ExpandMoreIcon}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        MenuProps={{
          style: {
            maxHeight: "34vh",
            top: "18px",
            left: "-8px",
            minWidth: "435px!important",
          },
        }}
        sx={{
          "& .MuiSelect-iconStandard": {
            marginRight: "15px",
            height: "20px",
            width: "20px",
          },
          "&	.MuiSelect-select": {
            padding: "0px 0px 0px 12px",
            fontSize: "10px",
            fontWeight: "bold",
            display: "flex",
            marginTop: "7px;",
            width: "450px",

            ":focus": {
              backgroundColor: "transparent",
            },
            " & .MuiSelect-select-MuiInputBase-input-MuiInput-input": {
              display: "flex",
              marginTop: "7px;",
            },

            " & .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root": {
              top: "10px!important",
            },
          },
        }}
      >
        <MenuItem
          className={styles.searchbale}
          sx={singlOuterList}
          disableRipple
          onKeyDown={(event) => event.stopPropagation()}
        >
          <SearchAbleInput
            searchPlaceholder="Search user"
            searchQuery={search}
            onChangeText={(e) => setSearch(e.target.value)}
            onResetHandler={() => setSearch("")}
          />
        </MenuItem>
        {list?.map((data, index) => {
          if (searchByFields([data?.name], search)) {
            return (
              <MenuItem key={index} value={data?._id}>
                <div
                  style={{ paddingBottom: "6px" }}
                  className={styles.commenttext}
                >
                  {data?.imageUrl?.original ? (
                    <img
                      alt=""
                      src={IMAGE_BASE_URL + data?.imageUrl?.original}
                      className={styles.adminThumImage}
                    />
                  ) : (
                    <h5
                      className={styles.adminInitail}
                    >{`${data?.name?.[0]}${data?.lastName?.[0]}`}</h5>
                  )}
                </div>
                <div className={styles.selectValue}>
                  <div
                    style={{ lineHeight: 1 }}
                    className={styles.nameStyles}
                  >{`${data?.name} ${data?.lastName}`}</div>
                  <div
                    style={{ lineHeight: 1, marginTop: "2px" }}
                    className={styles.emailStyle}
                  >
                    {data?.email}
                  </div>
                </div>
              </MenuItem>
            );
          }
        })}
      </Select>
    </>
  );
};
export default DeleteUserSection;
