import React, { useState, useEffect, Fragment } from "react";
import styles from "./styles.module.scss";
import Overview from "./overview/index";
import SideMenu from "../../../components/layout/sideMenu";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Settings from "./settings";
import { COMMENT_TYPE } from "../../../constants/contants";
import CommentsSection from "../../../components/commentSection";
import Assingnments from "./assingnmets";
import PointOfContacts from "./pointOfContacts";
import { APIS } from "../../../api/endpoints";
import Contracts from "./contract";
import Invoices from "./invoices";
import BillingDetails from "./billing";
import ConfirmationModal from "../../../components/modal/confirmationModal";
import { DELETE_MODAL_CONFIRMATION } from "../../../constants/contants";
import ToastMessage from "../../../components/layout/toast";
import { addEditApi, listApi } from "../../../api/commonApis";
import { toast } from "react-toastify";
import { ROUTES } from "../../../router/routes";
import {
  MODULES,
  PERMISSIONS,
  SUB_MODULES,
} from "../../../constants/accessControl";
import LightButton from "../../../components/buttons/lightButton";
import {
  ristrictAccess,
  sidePanelAccess,
  hasPermission,
} from "../../../utils/helper";
import AssingnmentsExt from "pages/assignmentExt";
import { TOAST_MESSAGE } from "constants/toastMessage";
import ChangePassword from "pages/talent/overview/changepassword";
import { getState } from "redux/store";
import OrgProfile from "./overview/orgProfile";
import Shortcuts from "pages/profile/shortcuts";
import TeamMembers from "./teammembers";

// import OrgProfile from "components/orgProfile";

const ClientsOverview = () => {
  ////// SIDE MENU BAR FOR CLINET////
  const MENU = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Assignments",
      id: "assignments",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ASSIGNMENTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.POINT_OF_CONTACTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.POINT_OF_CONTACTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Contracts",
      id: "contracts",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.CONTRACTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Invoices",
      id: "invoices",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.CONTRACTS,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Billing",
      id: "billing",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.BILLING,
        PERMISSIONS.READ
      ),
    },
    {
      label: "Client History",
      id: "history",
      hasAccess: hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.CLIENT_HISTORY,
        PERMISSIONS.READ
      ),
    },
  ];

  ////// SIDE MENU BAR FOR CLINET IN CASE OF RISTRICT////
  const [RISTRICTED_MENU, set_restricted_menu] = useState([
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    {
      label: "Contracts",
      id: "contracts",
      hasAccess: true,
    },
    {
      label: "Invoices",
      id: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      id: "billing",
      hasAccess: true,
    },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ]);

  const restricted_org_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    {
      label: "Contracts",
      id: "contracts",
      hasAccess: true,
    },
    {
      label: "Invoices",
      id: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      id: "billing",
      hasAccess: true,
    },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ];

  const restricted_ceo_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    {
      label: "Contracts",
      id: "contracts",
      hasAccess: true,
    },
    {
      label: "Invoices",
      id: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      id: "billing",
      hasAccess: true,
    },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ];

  const restricted_chro_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    // {
    //   label: "Contracts",
    //   id: "contracts",
    //   hasAccess: true,
    // },
    // {
    //   label: "Invoices",
    //   id: "invoices",
    //   hasAccess: true,
    // },
    // {
    //   label: "Billing",
    //   id: "billing",
    //   hasAccess: true,
    // },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ];

  const restricted_cfo_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    {
      label: "Contracts",
      id: "contracts",
      hasAccess: true,
    },
    {
      label: "Invoices",
      id: "invoices",
      hasAccess: true,
    },
    {
      label: "Billing",
      id: "billing",
      hasAccess: true,
    },
    // {
    //   label: "Assignments",
    //   id: "assignments-ext",
    //   hasAccess: true,
    // },
  ];

  const restricted_hr_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    // {
    //   label: "Contracts",
    //   id: "contracts",
    //   hasAccess: true,
    // },
    // {
    //   label: "Invoices",
    //   id: "invoices",
    //   hasAccess: true,
    // },
    // {
    //   label: "Billing",
    //   id: "billing",
    //   hasAccess: true,
    // },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ];

  const restricted_employee_menu = [
    {
      label: "Overview",
      id: "overview",
      hasAccess: true,
    },
    {
      label: "Point of Contacts",
      id: "contacts",
      hasAccess: true,
    },
    {
      label: "Team Members",
      id: "team-members",
      hasAccess: true,
    },
    // {
    //   label: "Contracts",
    //   id: "contracts",
    //   hasAccess: true,
    // },
    // {
    //   label: "Invoices",
    //   id: "invoices",
    //   hasAccess: true,
    // },
    // {
    //   label: "Billing",
    //   id: "billing",
    //   hasAccess: true,
    // },
    {
      label: "Assignments",
      id: "assignments-ext",
      hasAccess: true,
    },
  ];

  const { id } = useParams();
  const [params, setParams] = useSearchParams();
  const tab = params.get("tab");
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [clientData, setClientData] = useState();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [billingRefresh, setBillingRefresh] = useState(false);
  const [hasOrgDeleteAccess, setHasOrgDeleteAccess] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [hasCommentWriteAccess, setHasCommentWriteAccess] = useState(false);
  const [hasCommentDeleteAccess, setHasCommentDeleteAccess] = useState(false);
  const [isSidePanel, setIsSidePanel] = useState(false);
  const [comment_access, set_comment_access] = useState(false);
  const [sideMenuRefresh, setSideMenuRefresh] = useState(false);
  const [_user, set_user] = useState();

  const RefreshHanlder = () => {
    setSideMenuRefresh(!sideMenuRefresh);
  };
  //// GETTING PERMISSION FOR SAME COMPONENT//////

  useEffect(() => {
    const user = getState()?.auth?.data;
    set_user(getState()?.auth?.data);

    set_comment_access(!user.selfSignUp);

    setHasWriteAccess(
      hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ORGANIZATION_OVERVIEW,
        PERMISSIONS.WRITE
      )
    );
    setHasOrgDeleteAccess(
      hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.ORGANIZATION_OVERVIEW,
        PERMISSIONS.DELETE
      )
    );
    setHasCommentWriteAccess(
      hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.CLIENT_HISTORY,
        PERMISSIONS.WRITE
      )
    );
    setHasCommentDeleteAccess(
      hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.CLIENT_HISTORY,
        PERMISSIONS.DELETE
      )
    );

    if (_user?.organization_role == "Organization Admin") {
      set_restricted_menu(restricted_org_menu);
    }

    if (_user?.organization_role == "CxO / Co-Founder") {
      set_restricted_menu(restricted_ceo_menu);
    }

    if (_user?.organization_role == "CHRO") {
      set_restricted_menu(restricted_ceo_menu);
    }

    if (_user?.organization_role == "CFO") {
      set_restricted_menu(restricted_ceo_menu);
    }

    if (_user?.organization_role == "HR") {
      set_restricted_menu(restricted_hr_menu);
    }

    if (_user?.organization_role == "Recruiter") {
      set_restricted_menu(restricted_hr_menu);
    }

    if (_user?.organization_role == "Non Leadership") {
      set_restricted_menu(restricted_employee_menu);
    }

    if (_user?.organization_role == "Hiring Manager") {
      set_restricted_menu(restricted_employee_menu);
    }
  }, [_user]);

  if (!tab) {
    navigate(`/clients/overview/${id}?tab=overview`, { replace: true });
  }

  ///////// ALL CLIENT DATA ///////

  useEffect(() => {
    setLoading(true);
    listApi(APIS.LIST_CLIENT_OVERVIEW, { clientId: id })
      .then((result) => {
        setClientData(result?.data[0]);
      })
      .finally(() => setLoading(false));
  }, [refresh, billingRefresh]);

  /////////// HANLDER FOR DELETE CUREENT ORGANISATION////////

  const handleDeleteOrganization = () => {
    addEditApi(APIS.DELETE_DOCUMENT_DATA, { type: 3, id: id }).then(
      (result) => {
        handleClose();
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.DELETE_ORGANISATION}
          />
        );

        navigate(`${ROUTES.CLIENTS}`);
      }
    );
  };

  ////////////// HANDLER FOR RENDERING THE PAGE ACCORDING TO IT CASE////////

  const renderTab = () => {
    switch (tab) {
      case "assignments":
        return <Assingnments client={clientData} />;
      case "contacts":
        return <PointOfContacts client={clientData} />;
      case "team-members":
        return <TeamMembers client={clientData} />;
      case "contracts":
        return <Contracts />;
      case "invoices":
        return <Invoices />;
      case "billing":
        return (
          <BillingDetails
            client={clientData}
            setRefresh={setBillingRefresh}
            refresh={billingRefresh}
          />
        );
      case "assignments-ext":
        return <AssingnmentsExt headData={clientData} />;
      case "history":
        return (
          <CommentsSection
            check={true}
            id={id}
            title="Client History"
            type={COMMENT_TYPE.ORGANIZATION}
            renderAsTab
          />
        );
      case "change-password":
        return <ChangePassword></ChangePassword>;
      case "tl-shortcuts":
        return <Shortcuts></Shortcuts>;
      case "profile":
        return <OrgProfile RefreshHanlder={RefreshHanlder}></OrgProfile>;

      default:
        return <Overview client={clientData} />;
    }
  };

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  // check if user is authorized to access this route

  useEffect(() => {
    const current_user = getState()?.auth?.data;

    if (current_user.talentLabRole === "Organizations") {
      if (current_user.commonId !== id) {
        navigate("/");
      }
    }

    if (current_user.talentLabRole === "Talent") {
      navigate("/");
    }
  }, []);

  /////////// RETURN ////////////

  return (
    <section
      className={`${styles.hasStyles} ${
        ristrictAccess() || !isSidePanel
          ? styles.centerOverview
          : styles.overview
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="mb-3 col-md-12">
            <div className="d-flex align-items-start">
              <div className="mb-3 ">
                <div className="d-flex-column">
                  <SideMenu
                    items={ristrictAccess() ? RISTRICTED_MENU : MENU}
                    isActive={(menu) => menu.id === tab}
                    onClick={(item) => {
                      if (item.href) {
                        navigate(`${item.href}/${id}?source=overview`);
                      } else {
                        setParams({ tab: item.id });
                      }
                    }}
                  />
                  {clientData?.closedCandidates?.length > 0 && (
                    <div className="mt-3 mb-3">
                      <div className={` me-3 ${styles.bgprofileleft}`}>
                        <div className="status">
                          <span className={`${`${styles.converted}`}`}>
                            Recently closed
                          </span>
                        </div>
                        <h4 className={styles.associatedCandidate}>
                          Associated Candidate
                        </h4>
                        {clientData?.closedCandidates?.map((data, index) => {
                          return (
                            <Fragment key={index}>
                              <h3 className={styles.candidateName}>
                                {`${data?.talentId?.firstName} ${
                                  data?.talentId?.middleName
                                    ? data?.talentId?.middleName
                                    : ""
                                } ${data?.talentId?.lastName}`}
                              </h3>
                              <p className={styles.candidateProfile}>
                                {data?.talentId?.recentWork?.designation
                                  ? `${data?.talentId?.recentWork?.designation}`
                                  : ""}
                              </p>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                {!ristrictAccess() && hasOrgDeleteAccess && (
                  <LightButton
                    onClick={handleOpen}
                    label="Delete Organization"
                  />
                )}
              </div>

              {renderTab()}
              <Settings
                hasCommentAccess={true}
                hasWriteAccess={hasWriteAccess}
                id={id}
                tab={tab}
                refresh={setRefresh}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={DELETE_MODAL_CONFIRMATION}
        open={open}
        closeModal={handleClose}
        onChangeHanlder={handleDeleteOrganization}
        data={clientData?.name}
        description="This will delete all its associated team members, assignments and organisation records."
      />
    </section>
  );
};

export default ClientsOverview;
