import { ref, array, object, string, mixed, number } from "yup";
const gstnnVali = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;
const panCardVali = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
const newUrl =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
const mobileNumber = /^\d{10}$/;
/////////////////////////////// Login Schema /////////////////////////////////////
export const loginSchema = object().shape({
  email: string().email("Invalid email").required("Required"),
  password: string().required("Required"),
});

/////////////////////////////// Forget Password Schema /////////////////////////////////////

export const ForgetPasswordSchema = object().shape({
  email: string().email("Invalid email").required("Required"),
});

/////////////////////////////// Reset Password Schema /////////////////////////////////////

export const ResetPassowrdSchema = object().shape({
  newPassowrd: string().required("Required"),
  confirmPassword: string().required("Required"),
});

/////////////////////////////// Talent Profile Schema /////////////////////////////////////
export const talentProfileSchema = object().shape({
  firstName: string().required("First name can't be empty"),
  lastName: string().required("Last name can't be empty"),
  // gender: mixed().required("Select gender"),
  // city: mixed().nullable().required("City can't be empty"),
  // state: mixed().nullable().required("State can't be empty"),
  // country: mixed().nullable().required("Country can't be empty"),
  // email: string().email("Invalid email"),
  // .required("Required"),
  // phoneNumber: array().of(
  //   string().required("Required").matches(mobileNumber, "Invalid phone number")
  // ),
  linkedinUrl: string().matches(newUrl, "URL is not valid"),
  twitterUrl: string().matches(newUrl, "URL is not valid"),
  // executiveSummary: string().required("Summary can't be empty"),
});

/////////////////////////////// Talent NEW CASE Profile Schema /////////////////////////////////////

export const talentNewCaseProfileSchema = object().shape({
  firstName: string().required("First name can't be empty"),
  lastName: string().required("Last name can't be empty"),
  // gender: mixed().required("Select gender"),
  // city: mixed().nullable().required("City can't be empty"),
  // state: mixed().nullable().required("State can't be empty"),
  // country: mixed().nullable().required("Country can't be empty"),
  // email: string().email("Invalid email"),
  // .required("Required"),
  // phoneNumber: array().of(
  //   string().required("Required").matches(mobileNumber, "Invalid phone number")
  // ),
  linkedinUrl: string().matches(newUrl, "URL is not valid"),
  twitterUrl: string().matches(newUrl, "URL is not valid"),
  // executiveSummary: string().required("Summary can't be empty"),
  // designation: string().required("Title/Designation can't be empty"),
  // role: string().required("Role can't be empty"),
  // companyName: string().required("Company Name can't be empty"),
});

/////////////////////////////// Login Schema /////////////////////////////////////

export const talentWorkSchema = object().shape({
  designation: string().required("Title/Designation can't be empty"),
  role: string().required("Role can't be empty"),
  companyName: string().required("Company Name can't be empty"),
  // companyUrl: string().matches(newUrl, "URL is not valid"),
  // .required("URL can't be empty")
  // city: mixed().nullable().required("City can't be empty"),
  // state: mixed().nullable().required("State can't be empty"),
  // country: mixed().nullable().required("Country can't be empty"),
  // workSynopsis: string().required("Work Synopsis can't be empty"),
  // keyWork: string().required("Key Work can't be empty"),
  // industryType: mixed().required("Industry Type can't be empty"),
  // functionalAreas: array()
  //   .min(1, "Functional areas can't be empty")
  //   .required("Functional areas can't be empty"),
  // skillRoles: array()
  //   .min(1, "Skill roles can't be empty")
  //   .required("Skill roles can't be empty"),
  // joinDateMonth: mixed().required("Select month"),
  // joinDateYear: mixed().required("Select year"),
  // endDateMonth: mixed().when("currentlyWork", {
  //   is: (value) => value === false,
  //   then: (rule) => rule.required("Select month"),
  // }),
  // endDateYear: mixed().when("currentlyWork", {
  //   is: (value) => value === false,
  //   then: (rule) => rule.required("Select year"),
  // }),
});

/////////////////////////////// Schema /////////////////////////////////////
export const talentEducationSchema = object().shape({
  degree: string().required("Degree can't be empty "),
  universityName: string().required("University Name can't be empty"),
  // educationSynopsis: string().required("Education Synopsis can't be empty"),
  // educationType: mixed().required("Select ducation type"),
  // joinDateMonth: mixed().required("Select month"),
  // joinDateYear: mixed().required("Select year"),
  // endDateMonth: mixed().when("currentlyStudy", {
  //   is: (value) => value === false,
  //   then: (rule) => rule.required("Select month"),
  // }),
  // endDateYear: mixed().when("currentlyStudy", {
  //   is: (value) => value === false,
  //   then: (rule) => rule.required("Select year"),
  // }),
});

/////////////////////////////// Schema /////////////////////////////////////
export const talentCompensationSchema = object().shape({
  // currency: mixed().required("Currency can't be empty"),
  askingCompensation: object().shape({
    fixed: number()
      .typeError("Invalid Fixed Component")
      .required("Fixed Component can't be empty"),
    //   variable: number()
    //     .typeError("Invalid Variable Bonuses")
    //     .required("Variable Bonuses can't be empty"),
    //   esops: number().typeError("Invalid ESOPs").required("esops can't be empty"),
  }),
  currentCompensation: object().shape({
    fixed: number()
      .typeError("Invalid Fixed Component")
      .required("Fixed Component can't be empty"),
    //   variable: number()
    //     .typeError("Invalid Variable variableBonuses")
    //     .required("Variable Bonuses can't be empty"),
    //   esops: number().typeError("Invalid ESOPs").required("esops can't be empty"),
  }),
});

/////////////////////////////// Cert Schema /////////////////////////////////////
export const talentCertificationsSchema = object().shape({
  title: string().required("Certificate title can't be empty"),
  instituteName: string().required("Institute name can't be empty"),
  // synopsis: string().required("Certification synopsis can't be empty"),
  // endDateMonth: mixed().required("Select month"),
  // endDateYear: mixed().required("Select year"),
  // url: string()
  //   .matches(newUrl, "URL is not valid")
  //   .required("URL can't be empty"),
});

/////////////////////////////// Dossier Schema /////////////////////////////////////
export const talentCandidateDossierSchema = object().shape({
  // dateProfileSent: mixed().required("Date profile sent can't be empty"),
  assignment: mixed().required("Assignment can't be empty"),
  shortRole: string().required("Role can't be empty"),
  // sector: string().required("Sector can't be empty"),
  // currentLocation: string().required("Current location can't be empty"),
});

/////////////////////////////// Compentancy Schema /////////////////////////////////////
export const talentCompentancySchema = object().shape({
  compentancyMapping: array().of(
    object().shape({
      name: string().required("Required"),
      description: string().required("Required"),
      score: number()
        .typeError("Invalid score")
        .min(1, "Must be in between 1-4")
        .max(4, "Must be in between 1-4"),
      notes: string(),
    })
  ),
});

/////////////////////////////// Add Assignment Schema /////////////////////////////////////

export const addAssignmnetSchema = object().shape({
  title: string().required("Assignment Title  can't be empty"),
  companyId: mixed().required("Company can't be empty"),
  // city: mixed().nullable().required("City can't be empty"),
  // state: mixed().nullable().required("State can't be empty"),
  // country: mixed().nullable().required("Country can't be empty"),
  // contactPerson: string().required("Name can't be empty"),
  // contactPersonDesignation: string().required("Designation can't be empty"),
  // contactPersonEmail: string().email("Invalid email").required("Required"),
  // contactPersonPhoneNumber: array().of(
  //   string().required("Required").matches(mobileNumber, "Invalid phone number")
  // ),
  // director: mixed().required("Assignment Director can't be empty"),
  // consultant: array()
  //   .min(1, "consultant can't be empty")
  //   .required("consultant can't be empty"),
});

/////////////////////////////// Add Organization OverView Schema /////////////////////////////////////

export const organizationOverviewSchema = object().shape({
  name: string().required("Name  can't be empty"),
  // legalEntityName: string().required("Legal entity name can't be empty"),
  // category: mixed().required("Category Type can't be empty"),
  // city: mixed().nullable().required("City can't be empty"),
  // state: mixed().nullable().required("State can't be empty"),
  // country: mixed().nullable().required("Country can't be empty"),
  // contactPersons: array().of(
  //   object().shape({
  //     name: string().required("Full Name can't be empty"),
  //     designation: string().required("Designation & Role can't be empty"),
  //     email: string().email("Invalid email").required("Required"),
  //     phoneNumber: string()
  //       .required("Required")
  //       .matches(mobileNumber, "Invalid phone number"),
  //   })
  // ),
  // linkedinUrl: string().matches(newUrl, "URL is not valid"),
  // .required("URL can't be empty"),
  // twitterUrl: string().matches(newUrl, "URL is not valid"),
  // .required("URL can't be empty"),
  // summary: string().required("Summary  can't be empty"),
  // director: mixed().required("Industry Type can't be empty"),
  // manager: array()
  //   .min(1, "Functional areas can't be empty")
  //   .required("Functional areas can't be empty"),
});

/////////////////////////////// Ristrict Organization OverView Schema /////////////////////////////////////

export const ristrictOrgAddEditSchema = object().shape({
  name: string().required("Name  can't be empty"),
  //legalEntityName: string().required("Legal entity name can't be empty"),
  //  category: mixed().required("Category Type can't be empty"),
  // city: mixed().nullable().required("City can't be empty"),
  // state: mixed().nullable().required("State can't be empty"),
  // country: mixed().nullable().required("Country can't be empty"),
  // contactPersons: array().of(
  //   object().shape({
  //     name: string().required("Full Name can't be empty"),
  //     designation: string().required("Designation & Role can't be empty"),
  //     email: string().email("Invalid email").required("Required"),
  //     phoneNumber: string()
  //       .required("Required")
  //       .matches(mobileNumber, "Invalid phone number"),
  //   })
  // ),
  //  linkedinUrl: string().matches(newUrl, "URL is not valid"),
  // .required("URL can't be empty"),
  //  twitterUrl: string().matches(newUrl, "URL is not valid"),
  // .required("URL can't be empty"),
  // summary: string().required("Summary  can't be empty"),
  /* director: mixed().required("Industry Type can't be empty"),
  // manager: array()
  //   .min(1, "Functional areas can't be empty")
  //   .required("Functional areas can't be empty"),*/
});

/////////////////////////////// Clinet Billing Schema /////////////////////////////////////

export const clinetBillingSchema = object().shape({
  billingContact: object().shape({
    // name: string().required("First name can't be empty"),
    name: string().required("Name can't be empty"),
    // designation: string().required("Designation/Role can't be empty"),
    email: string().email("Invalid email").required("Email can't be empty"),
    // phoneNumber: string()
    //   .required("Required")
    //   .matches(mobileNumber, "Invalid phone number"),
  }),
  // billingAddress: object().shape({
  //   addressLine1: string().required("Address can't be empty"),
  //   addressLine2: string().required("Address can't be empty"),
  //   city: mixed().nullable().required("City can't be empty"),
  //   state: mixed().nullable().required("State can't be empty"),
  //   country: mixed().nullable().required("Country can't be empty"),
  // }),
  gstNo: string()
    .matches(gstnnVali, "GSTIN is not valid")
    .required("GSTIN can't be empty"),
  // panCard: string()
  //   .matches(panCardVali, "PAN Number is not valid")
  //   .required("PAN Number can't be empty"),
});

///////////////////////////// userProfileSchema /////////////////////////////////

export const userProfileSchema = object().shape({
  name: string().required("First name can't be empty"),
  lastName: string().required("Last name can't be empty"),
  roleName: string(),
  email: string().email("Invalid email").required("Required"),
  phoneNumber: string().matches(mobileNumber, "Invalid phone number"),
  /*talentLabRole: mixed().required("TalentLab Role  can't be empty"),*/
});

export const reminderSchema = object().shape({
  title: string().required("Title can't be empty"),
});

////////////DataSet=>ext///////////////

export const dataSetAddEditSchema = object().shape({
  name: string().required("name can't be empty"),
  /* description: string().required("description can't be empty").nullable(),*/
});
////////////DataSet=>IndustryType///////////////

export const dataSetIndusryTypeSchema = object().shape({
  name: string().required("name can't be empty"),
  /* description: string().required("description can't be empty").nullable(),*/
});

export const talentLabUserSchema = object().shape({
  name: string().required("First name can't be empty"),
  lastName: string().required("Last name can't be empty"),
  email: string().email("Invalid email").required("Required"),
  // phoneNumber: string()
  //   .required("Required")
  //   .matches(mobileNumber, "Invalid phone number"),

  // talentLabRole: object()
  //   .typeError("Role can't be empty")
  //   .required("Role can't be empty"),
  // organization: string().required(
  //   "Organizational Role name can't be empty"
  // ),
  // password: string().required("Required"),
});

export const talentLabOrgUserSchema = object().shape({
  name: string().required("First name can't be empty"),
  // lastName: string().required("Last name can't be empty"),
  email: string().email("Invalid email").required("Email is required"),
  // phoneNumber: string()
  //   .required("Required")
  //   .matches(mobileNumber, "Invalid phone number"),

  // talentLabRole: object()
  //   .typeError("Role can't be empty")
  //   .required("Role can't be empty"),
  // organization: string().required(
  //   "Organizational Role name can't be empty"
  // ),
  organization_role: object()
    .typeError("Organizational role name can't be empty")
    .required("Organizational role name can't be empty"),

  // password: string().required("Required"),
});

//////////// UPDATE PASSOWRD SCHEMA ///////////////

export const userChangePassword = object().shape({
  currentPassword: string().required("Current password can't be empty"),
  newPassword: string().required("New password can't be empty"),
  confirmNewPassword: string()
    .oneOf([ref("newPassword"), ""], "Both password should be same")
    .required("Confirm passowrd can't be empty"),
});
