import React, { useState, useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { MenuItem } from "@mui/material";
import styles from "./styles.module.scss";
import { listApi } from "../../../api/commonApis";
import { APIS } from "../../../api/endpoints";
import { IMAGE_BASE_URL } from "../../../constants/contants";
import { useSelector } from "react-redux";
import { IMAGES } from "../../../constants/assets";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SendIcon from "../../icons/sendIcon";
import { ristrictAccess, sidePanelAccess } from "utils/helper";
import cloneDeep from "lodash/cloneDeep";
import { getState } from "redux/store";
import { useParams } from "react-router-dom";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    // maxWidth: "220px",
    width: "240px",
    padding: "12px 0px 8px 0px",
    marginRight: "90px",
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0 0px 0px rgba(0,0,0,0.25), 0 0px 10px rgba(0,0,0,0.22)",
  },
}));

const ITEM_HEIGHT = 48;

const MentionItem = (suggestion) => {
  const type = suggestion.type;
  let itemContent = "";
  let intail = "";
  let bottomHeading = "";
  let imageUrl = "";
  let experienceInYears = "";

  if (type === 1) {
    itemContent = suggestion?.display;
    intail = `${suggestion.firstName[0]}${suggestion.lastName[0]}`;
    bottomHeading = suggestion?.email;
    imageUrl = suggestion.imageUrl;
    experienceInYears = suggestion.experienceInYears;
  }
  if (type === 2) {
    itemContent = suggestion?.display;
    intail = `${suggestion.title[0]}`;
    bottomHeading =
      suggestion?.city && suggestion?.state && suggestion?.country
        ? `${suggestion?.city}, ${suggestion?.state}, ${suggestion?.country}`
        : "";
    imageUrl = suggestion?.imageUrl;
  }

  return (
    <>
      <MenuItem
        className={styles.mentions__suggestions__menu__item}
        data-testid={`menu-item-${itemContent}`}
      >
        <div className={styles.commenttext}>
          {imageUrl?.original ? (
            <img
              alt=""
              src={IMAGE_BASE_URL + imageUrl?.original}
              className={styles.adminThumImage}
            />
          ) : (
            <h5 className={styles.adminInitail}>{intail}</h5>
          )}
        </div>
        <div>
          <div className={styles.nameStyles}>{itemContent}</div>
          <div className={`d-flex ${styles.emailContainer}`}>
            <div className={styles.emailStyle}>{bottomHeading}</div>

            {experienceInYears && (
              <>
                <div className={styles.dotStyle}></div>
                <div className={styles.yearStyle}>{`${experienceInYears.toFixed(
                  1
                )} Years`}</div>
              </>
            )}
          </div>
        </div>
      </MenuItem>
      <hr className={styles.divider} />
    </>
  );
};

const CommentInput = ({
  renderAsTab,
  onAddComment,
  setInputFocus,
  fromReply,
  entity,
  // placeholder = "Post a comment.",
}) => {
  const [commentInput, setCommentInput] = useState("");
  const [mentions, setMentions] = useState([]);
  const _id = useSelector((state) => state.auth.data._id);
  const [isSidePanel, setIsSidePanel] = useState(false);

  const commentHandler = () => {
    onAddComment(commentInput, mentions);
    setCommentInput("");
  };

  const { id } = useParams();

  const fetchTalents = (search, callback) => {
    // if (!search) return;
    listApi(APIS.LIST_TALENTS, { search })
      .then((res) =>
        res?.data?.data?.map((user) => ({
          display: `${user.firstName} ${
            user.middleName ? user.middleName : ""
          } ${user.lastName}`,
          firstName: user.firstName,
          lastName: user.lastName,
          id: user._id,
          email: user.email,
          experienceInYears: user?.recentWork?.experienceInYears,
          imageUrl: user.imageUrl,
          type: 1,
        }))
      )
      .then(callback);
  };

  const fetchTeams = (search, callback) => {
    // if (!search) return;
    listApi(APIS.LIST_GET_ADMIN, { search, skipAdminId: _id })
      .then((res) =>
        res?.data
          ?.filter((t) => t?.commonId === id)
          .map((user) => ({
            display: `${user.name} ${user.middleName ? user.middleName : ""} ${
              user.lastName
            }`,
            firstName: user.name,
            lastName: user.lastName,
            id: `admin_id_${user._id}`,
            email: user.email,
            imageUrl: user.imageUrl,
            type: 1,
          }))
      )
      .then(callback);
  };

  const fetchOrgTeams = (search, callback) => {
    // if (!search) return;
    listApi(APIS.LIST_GET_ADMIN, { search, skipAdminId: _id })
      .then((res) =>
        res?.data
          ?.filter((t) => t?.commonId === user.commonId)
          .map((user) => ({
            display: `${user.name} ${user.middleName ? user.middleName : ""} ${
              user.lastName
            }`,
            firstName: user.name,
            lastName: user.lastName,
            id: `admin_id_${user._id}`,
            email: user.email,
            imageUrl: user.imageUrl,
            type: 1,
          }))
      )
      .then(callback);
  };

  const fetchAdmins = (search, callback) => {
    // if (!search) return;
    listApi(APIS.LIST_ADMIN, {
      talentLabRole: "admins",
    })
      .then((res) =>
        res?.data.data?.map((user) => ({
          display: `${user.name} ${user.middleName ? user.middleName : ""} ${
            user.lastName
          }`,
          firstName: user.name,
          lastName: user.lastName,
          id: `admin_id_${user._id}`,
          email: user.email,
          imageUrl: user.imageUrl,
          type: 1,
        }))
      )
      .then(callback);
  };

  const org_team_members = (search, callback) => {
    const user = getState()?.auth?.data;
    listApi(APIS.LIST_ADMIN, {
      search,
      commonId: user?.commonId,
    })
      .then((result) => {
        result?.data?.data?.map((user) => ({
          display: `${user.name} ${user.middleName ? user.middleName : ""} ${
            user.lastName
          }`,
          firstName: user.name,
          lastName: user.lastName,
          id: `admin_id_${user._id}`,
          email: user.email,
          imageUrl: user.imageUrl,
          type: 1,
        }));
      })
      .then(callback);
  };

  const fetchAccountOwners = (search, callback) => {
    // if (!search) return;

    let client_data;
    let account_owners;

    listApi(APIS.LIST_CLIENT_OVERVIEW, { clientId: id })
      .then((result) => {
        client_data = result.data[0];
        account_owners = [...client_data.manager, client_data.director];
        console.log(account_owners);
        return account_owners.map((user) => ({
          display: `${user.name} ${user.middleName ? user.middleName : ""} ${
            user.lastName
          }`,
          firstName: user.name,
          lastName: user.lastName,
          id: `admin_id_${user._id}`,
          email: user.email,
          imageUrl: user.imageUrl,
          type: 1,
        }));
      })
      .then(callback);

    // const account_owners = client_data;
    // console.log(account_owners, "account_owners");

    // listApi(APIS.LIST_GET_ADMIN, { search, skipAdminId: _id })
    //   .then((res) =>
    //     res?.data?.map((user) => ({
    //       display: `${user.name} ${user.middleName ? user.middleName : ""} ${
    //         user.lastName
    //       }`,
    //       firstName: user.name,
    //       lastName: user.lastName,
    //       id: `admin_id_${user._id}`,
    //       email: user.email,
    //       imageUrl: user.imageUrl,
    //       type: 1,
    //     }))
    //   )
    //   .then(callback);
  };

  const fetchAssignments = (search, callback) => {
    // if (!search) return;
    listApi(APIS.LIST_ASSIGNMENTS_ORG_TALNET, { clientId: id })
      .then((res) => {
        const data = [...res?.data?.ongoing, ...res?.data?.archive];
        console.log(data, "LIST_ASSIGNMENTS_ORG_TALNET");
        return data
          ?.filter((a) => a?.companyId?._id === id)
          .map((assignment) => ({
            display: assignment?.title,
            title: assignment?.title,
            id: assignment?._id,
            city: assignment?.city,
            state: assignment?.state,
            country: assignment?.country,
            imageUrl: assignment?.companyId?.imageUrl,
            type: 2,
          }));
      })

      .then(callback);
  };

  const fetchOrganizations = (search, callback) => {
    // if (!search) return;
    listApi(APIS.LIST_CLIENT, { search })
      .then((res) =>
        res?.data?.data.map((client) => ({
          display: client?.name,
          title: client?.name,
          id: client._id,
          imageUrl: client?.imageUrl,
          city: client?.city,
          state: client?.state,
          country: client?.country,
          type: 2,
        }))
      )
      .then(callback);
  };

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  const user = getState()?.auth?.data;

  ///// RETURN /////

  return (
    <>
      <div
        className={
          renderAsTab
            ? styles.tabViewContainer
            : ristrictAccess()
            ? styles.ristrictConatiner
            : isSidePanel
            ? styles.container
            : styles.noPanelConatainer
        }
      >
        <MentionsInput
          placeholder="Post a comment."
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          value={commentInput}
          onChange={(e, _, __, mentions) => {
            setCommentInput(e.target.value);
            setMentions(mentions.map((x) => x.id));
          }}
          className={renderAsTab ? "tabMentions" : "mentions"}
          classNames={styles}
          // placeholder={placeholder}
          a11ySuggestionsListLabel={"Suggested mentions"}
        >
          {/* <Mention
            markup="@@@____id__^^^____display__@@@^^^"
            trigger={
              user.talentLabRole === "Organizations" ||
              user.talentLabRole === "Talent"
                ? "/@@@@@"
                : "@"
            }
            data={fetchTalents}
            className={styles.mentions__mention}
            renderSuggestion={MentionItem}
            appendSpaceOnAdd
          /> */}

          <Mention
            markup="ttt____id__^^^____display__@@@^^^"
            trigger={user.talentLabRole === "Talent" ? "/ttttt" : "/t"}
            data={
              user.talentLabRole === "Organizations"
                ? fetchOrgTeams
                : fetchTeams
            }
            className={styles.mentions__mention}
            renderSuggestion={MentionItem}
            appendSpaceOnAdd
          />

          <Mention
            markup="ppp____id__^^^____display__@@@^^^"
            trigger={user.talentLabRole === "Talent" ? "/ppppp" : "/p"}
            data={
              user.talentLabRole === "Organizations" ||
              user.talentLabRole === "Talent"
                ? fetchAdmins
                : fetchAdmins
            }
            className={styles.mentions__mention}
            renderSuggestion={MentionItem}
            appendSpaceOnAdd
          />

          <Mention
            markup="aaa____id__^^^____display__@@@^^^"
            trigger={user.talentLabRole === "Talent" ? "/aaaaa" : "/a"}
            data={fetchAssignments}
            className={styles.mentions__mention}
            renderSuggestion={MentionItem}
            appendSpaceOnAdd
          />

          <Mention
            markup="ooo____id__^^^____display__@@@^^^"
            trigger={
              user.talentLabRole === "Organizations" ||
              user.talentLabRole === "Talent"
                ? "/ooooo"
                : "/o"
            }
            data={fetchOrganizations}
            className={styles.mentions__mention}
            renderSuggestion={MentionItem}
            appendSpaceOnAdd
          />

          <Mention
            markup="ooo____id__^^^____display__@@@^^^"
            trigger={user.talentLabRole === "Organizations" ? "/m" : "/mmmmm"}
            data={fetchAccountOwners}
            className={styles.mentions__mention}
            renderSuggestion={MentionItem}
            appendSpaceOnAdd
          />

          {/* <Mention
            markup="ttt____id__^^^____display__@@@^^^"
            trigger={user.talentLabRole === "Organizations" ? "/t" : "/tttttt"}
            data={org_team_members}
            className={styles.mentions__mention}
            renderSuggestion={MentionItem}
            appendSpaceOnAdd
          /> */}
        </MentionsInput>

        <div
          className={
            renderAsTab
              ? styles.buttonContainer
              : ristrictAccess()
              ? styles.ristrictBtnContainer
              : // : isSidePanel
                styles.tabButtonContainer
            // : styles.centerButton
          }
        >
          {user.talentLabRole !== "Talent" && (
            <HtmlTooltip
              title={
                <React.Fragment>
                  {/* {!user.selfSignUp && (
                    <div className={styles.textContainer}>
                      <h5 className={styles.tipContent}>
                        Use '@' to tag a <b>Candidate</b>
                      </h5>
                    </div>
                  )} */}
                  {/* {!user.selfSignUp && (
                    <div className={styles.textContainer}>
                      <h5 className={styles.tipContent}>
                        Use '/o' to tag an <b>Organization</b>
                      </h5>
                    </div>
                  )} */}
                  {!user.selfSignUp && (
                    <div className={styles.textContainer}>
                      <h5 className={styles.tipContent}>
                        Use '/p' to tag an <b>Admin</b>
                      </h5>
                    </div>
                  )}
                  {user.talentLabRole !== "Talent" && (
                    <div className={styles.textContainer}>
                      <h5 className={styles.tipContent}>
                        Use '/a' to tag an <b>Assignment</b>
                      </h5>
                    </div>
                  )}
                  {!user.selfSignUp && (
                    <div className={styles.textContainer}>
                      <h5 className={styles.tipContent}>
                        Use '/t' to tag a <b>Team Member</b>
                      </h5>
                    </div>
                  )}
                  {user.selfSignUp &&
                    user.talentLabRole === "Organizations" && (
                      <div className={styles.textContainer}>
                        <h5 className={styles.tipContent}>
                          Use '/m' to tag an{" "}
                          <b>Account Owner or Account Directors</b>
                        </h5>
                      </div>
                    )}

                  {user.selfSignUp &&
                    user.talentLabRole === "Organizations" && (
                      <div className={styles.textContainer}>
                        <h5 className={styles.tipContent}>
                          Use '/t' to tag a<b> Team Member</b>
                        </h5>
                      </div>
                    )}
                </React.Fragment>
              }
              placement="bottom"
            >
              <img
                alt=""
                src={IMAGES.I_ICON}
                className={
                  renderAsTab ? `${styles.iPageStyles}` : `${styles.iTabStyles}`
                }
              />
            </HtmlTooltip>
          )}

          <button
            className={
              renderAsTab
                ? `${styles.pageChat} ${!commentInput && styles.disableButton}`
                : `${ristrictAccess() ? styles.ristrictBtn : styles.tabChat} ${
                    !commentInput && styles.disableButton
                  }`
            }
            disabled={!commentInput}
            onClick={commentHandler}
          >
            send
          </button>
          <div
            onClick={() => {
              if (commentInput) {
                commentHandler();
              }
            }}
            className={
              ristrictAccess() ? styles.resitrictIcon : styles.sendIcon
            }
          >
            <SendIcon color={commentInput ? "#011cf5" : "gray"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentInput;
