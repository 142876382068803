import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../../constants/assets";
import { listApi, addEditApi } from "../../../../api/commonApis";
import { APIS } from "../../../../api/endpoints";
import CustomTable from "../components/customTable";
import Pagination from "../../../../components/table/pagination";
import debounce from "lodash/debounce";
import ConfirmationModal from "../../../../components/modal/confirmationModal";
import { DELETE_MODAL_CONFIRMATION } from "../../../../constants/contants";
import { toast } from "react-toastify";
import ToastMessage from "../../../../components/layout/toast";
import { hasPermission } from "../../../../utils/helper";
import {
  PERMISSIONS,
  MODULES,
  SUB_MODULES,
} from "../../../../constants/accessControl";
import PrimaryButton from "../../../../components/buttons/primaryButton";
import UserIcon from "../../../../components/icons/userIcon";
import BasicConfirmationModal from "../../../../components/modal/basicConfirmationModal";
import { TOAST_MESSAGE } from "constants/toastMessage";

const Overview = ({ addEditHanlder, RowSelected, role = "admins" }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [adminList, setAdminList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [selectedData, setSelectedData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [check, setCheck] = useState("");
  const open = Boolean(anchorEl);
  const [filter, setFilter] = useState(" ");
  const [transferId, setTransferId] = useState("");
  const [accessControl, setAccessControl] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setAccessControl(
      hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.WRITE
      )
    );
  }, []);

  /*Filter Handler */

  const filterHandler = () => {
    if (filter === " ") {
      setFilter(2);
    }
    if (filter === 2) {
      setFilter(1);
    }
    if (filter === 1) {
      setFilter(2);
    }
  };

  /////////// INDUSTRY TYPE LISTING FOR TABLE (API)/////////////

  useEffect(() => {
    let payload = {
      //for_admin: true,
    };
    setLoading(true);

    if (selectedData?._id) {
      payload.skipAdminId = selectedData?._id;
    }

    if (filter !== " ") {
      payload.sort = filter;
    }
    listApi(APIS.LIST_CLIENT, {
      search,
      skip: limitPerPage * page,
      limit: limitPerPage,
      ...payload,
    })
      .then((result) => {
        setAdminList(result?.data?.data);
        setCount(result?.data?.count);
      })
      .finally(() => setLoading(false));
  }, [role, page, search, limitPerPage, refresh, filter, check === 1 || ""]);

  ///Tto get Data of the selected Row when Clicked on the ThreeDot Button/////

  const handleClick = (event, data) => {
    setSelectedData(data);
    RowSelected(data);
    setAnchorEl(event.currentTarget);
  };

  //////////to open and Close the Confirmation for DELETE ADMIN //////////

  const openConfirmHanlder = (check) => {
    if (check === "delete") {
      setCheck(1);
    }
    if (check === "block") {
      setCheck(2);
    }

    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setCheck(""); /// Reset Check When Confirmations Is Closed
    setSelectedData(); /// Reset SELECTED DATA When Confirmations Is Closed
    setOpenConfirmation(false); /// Close Confirmations
  };

  ////////// HANDLER DELETE AND BLOCK ADMIN (API)  ///////

  const confirmationHandler = () => {
    if (check === 1) {
      addEditApi(APIS.DELETE_DOCUMENT_DATA, {
        id: selectedData?._id,
        type: 5,
        transferId: transferId,
      }).then((result) => {
        setRefresh(!refresh);
        handleCloseConfirmation();
        toast.success(
          <ToastMessage title={"Success"} message={TOAST_MESSAGE.DELETE_USER} />
        );
      });
    }
    if (check === 2) {
      addEditApi(APIS.BLOCK_DATA, {
        id: selectedData?._id,
        type: 3,
        status: !selectedData?.isBlocked,
      }).then((result) => {
        setRefresh(!refresh);
        handleCloseConfirmation();
        toast.success(
          <ToastMessage
            title={"Success"}
            message={
              selectedData?.isBlocked
                ? TOAST_MESSAGE.UNBLOCKED_USER
                : TOAST_MESSAGE.BLOCKED_USER
            }
          />
        );
      });
    }
  };

  //////////// HANLDER FOR ADMIN TABLE////////////
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onPageChange = (pageNo) => {
    setPage(pageNo);
  };

  //////////// HANDLER FOR SEARCHING ///////////////
  const SearchedData = (event) => {
    setSearch(event?.target?.value);
  };
  const searchHandler = debounce(SearchedData, 300);

  /////////////HANDLER FOR OPEN AND CLOSE A PASSWODR CHANGE MODAL///////

  const passowrdModalHandler = () => {
    setOpenModal(true);
  };

  const closePassModalHandler = () => {
    setSelectedData();
    setOpenModal(false);
  };

  const passwordClickHanlder = () => {
    addEditApi(APIS.FORGOT_PASSWORD, { email: selectedData?.email }).then(
      () => {
        toast.success(
          <ToastMessage title={"Success"} message={TOAST_MESSAGE.RESET} />
        );
        closePassModalHandler();
      }
    );
  };

  //////CONTENT FOR CHANGE PASSOWRD MODAL////////////
  const modalContent = (selectedData) => {
    return (
      <>
        You are about to reset
        <b>
          {` ${selectedData?.name} 
         ${selectedData?.middleName && selectedData?.middleName}
        ${selectedData?.lastName} `}
        </b>
        password. This will send email instructions to reset password.
      </>
    );
  };

  ////////////////////////////////////////////////////////

  return (
    <div className={`tab-content ${styles.tabcontent}`} id="v-pills-tabContent">
      <div className={styles.edithead}>
        <div className={styles.topheading}>
          <h3 className={styles.h3heading}>Organizations</h3>
          {/* <div className="d-flex">
            <PrimaryButton
              visible={accessControl}
              leftImageClassName={styles.addTalentUser}
              leftIcon={UserIcon}
              label="Add Users"
              onClick={(event) => addEditHanlder(event, true)}
            />
          </div> */}
        </div>
      </div>
      <hr className={styles.cardDivider} />

      <div className={styles.middleCard}>
        <div className={styles.searchInputContainer}>
          <input
            type="text"
            className={styles.searchInputDataSets}
            placeholder="Search"
            onChange={searchHandler}
          />

          <img
            alt="search"
            src={IMAGES.SEARCH_ICON}
            className={styles.searchIcon}
          />
        </div>
      </div>

      <CustomTable
        passowrdModalHandler={passowrdModalHandler}
        addEditHanlder={addEditHanlder}
        open={open}
        handleClose={handleClose}
        handleClick={handleClick}
        anchorEl={anchorEl}
        list={adminList}
        loading={loading}
        openConfirmHanlder={openConfirmHanlder}
        status={selectedData?.isBlocked}
        filterHandler={filterHandler}
        filter={filter}
      />
      <div className={styles.paginationContiner}>
        {adminList.length > 0 && (
          <Pagination
            totalCount={count}
            currentPage={page}
            limitPerPage={limitPerPage}
            onPageChange={onPageChange}
            setLimitPerPage={setLimitPerPage}
          />
        )}
        <ConfirmationModal
          list={adminList}
          title={DELETE_MODAL_CONFIRMATION}
          open={openConfirmation}
          closeModal={handleCloseConfirmation}
          onChangeHanlder={confirmationHandler}
          data={`${selectedData?.name} ${selectedData?.lastName} `}
          status={selectedData?.isBlocked}
          block={check === 2 ? true : false}
          blockUser={true}
          deleteTalentLabUser
          deleteTransferTitle={`Select a user to transfer ${selectedData?.name}'s records`}
          setTransferId={setTransferId}
          transferId={transferId}
        />

        <BasicConfirmationModal
          closeModal={closePassModalHandler}
          openConfirmation={openModal}
          passwordChange
          data={modalContent(selectedData)}
          buttonLabel="Reset Password"
          cloneConfirmHandler={passwordClickHanlder}
        />
      </div>
    </div>
  );
};

export default Overview;
