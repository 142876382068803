import React from "react";
import styles from "./styles.module.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getState } from "redux/store";
import { useEffect } from "react";

const AddEditAssignment = () => {
  const { id } = useParams();

  // check if user is authorized to access this route
  const navigate = useNavigate();
  useEffect(() => {
    const current_user = getState()?.auth?.data;

    if (
      current_user.talentLabRole === "Organizations" ||
      current_user.talentLabRole === "Talent"
    ) {
      navigate("/");
    }
  }, []);

  return (
    <section className={styles.overview}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={styles.heading}>
              <h2>{id ? "Edit assignment" : "Add a new assignment"}</h2>
            </div>
          </div>
          <div className="mt-4 col-md-12">
            <div className="d-flex align-items-start">
              <div className={`tab-content ${styles.tabcontent}`}>
                <div className="fade show active">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddEditAssignment;
