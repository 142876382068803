import React from "react";
import "./index.css";
import { useState } from "react";
import { createPortal } from "react-dom";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { APIS } from "api";
import axios from "axios";
import ToastMessage from "components/layout/toast";
import { toast } from "react-toastify";
import { addEditApi } from "api/commonApis";

const CreateProfileButton = ({ talent }) => {
  // confirmation dialogue state
  const [confirmation, set_confirmation] = useState(false);

  // confirmation dialogue open handler
  const open_confirmation_handler = () => {
    set_confirmation(true);
  };

  // confirmation dialogue close handler
  const close_confirmation_handler = () => {
    set_confirmation(false);
  };

  // profile id using params
  const { id } = useParams();

  const navigate = useNavigate();

  const base_url =
    process.env.NODE_ENV === "development"
      ? "http://Milkeshs-MacBook-Pro.local:8002"
      : "https://tlsapi.peopleasset.in";

  const [loading, set_loading] = useState(false);

  // function to create the user
  const create_user_login = async () => {
    try {
      set_loading(true);

      addEditApi(APIS.ADD_EDIT_TALENT_PROFILE, {
        talentId: talent._id,
        has_taken_walkthrough: false,
        has_skiped_walkthrough: false,
        selfSignUp: true,
        admin_generated_login: true,
      }).then(async (response) => {
        console.log(response);

        if (response.statusCode === 200) {
          // using formdata as this part accepts form data due to multipart
          const formdata = new FormData();
          formdata.append("name", talent.firstName);
          formdata.append("middleName", talent.middleName);
          formdata.append("lastName", talent.lastName);
          formdata.append("email", talent.email);
          formdata.append("talentLabRole", "Talent");
          formdata.append("roles", JSON.stringify([]));
          formdata.append("selfSignUp", true);
          formdata.append("admin_generated_login", true);
          formdata.append("commonId", talent._id);

          const user_response = await axios.post(
            `${base_url}/user/create-talent-user`,
            formdata
          );

          if (user_response.data.statusCode === 200) {
            set_loading(false);
            set_confirmation(false);
            toast.success(
              <ToastMessage
                title={"Login created successfully"}
                // message={"The page will refresh automatically"}
              />,
              {
                autoClose: 20000,
              }
            );
            setTimeout(() => {
              window.location.href = `/profile?tab=tl-talents`;
            }, 2000);
          }
        }
      });
    } catch (error) {
      toast.error(
        <ToastMessage
          title={"Error"}
          message={"There was an error creating the profile"}
        />,
        {
          autoClose: 2000,
        }
      );
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          if (!talent?.admin_generated_login) {
            open_confirmation_handler();
          }
        }}
        className="lg:hover:tw-bg-[#011fc5]  lg:tw-bg-[#f4f4f4] tw-shadow-sm lg:tw-text-black flux lg:hover:tw-text-white tw-px-3 tw-py-2 tw-rounded-md tw-text-[12px]"
      >
        Create Login
      </button>
      {/* <div className="tw-flex tw-flex-col tw-justify-center tw-text-center tw-mr-6 tw-rounded-md tw-shadow tw-p-4 tw-bg-white">
        <p className="mb-2 tw-text-xs tw-m-0 tw-text-black">
          {talent?.admin_generated_login
            ? "User login generated"
            : "Create user login?"}
        </p>
        <div className="tw-flex tw-items-center tw-justify-center">
          <div
            onClick={() => {
              if (!talent?.admin_generated_login) {
                open_confirmation_handler();
              }
            }}
            className={`tw-w-16 tw-h-8 tw-rounded-full tw-p-2  tw-flex tw-items-center ${
              talent?.admin_generated_login
                ? "tw-justify-end tw-bg-green-200 tw-cursor-not-allowed"
                : "tw-bg-[#f4f4f4] tw-cursor-pointer"
            }`}
          >
            <button
              onClick={() => {
                if (!talent?.admin_generated_login) {
                  open_confirmation_handler();
                }
              }}
              disabled={talent?.admin_generated_login}
              className={`tw-w-5 tw-h-5 tw-rounded-full tw-shadow-lg  ${
                talent?.admin_generated_login
                  ? "tw-bg-green-700 tw-cursor-not-allowed"
                  : "tw-bg-[#e4e4e4]"
              }`}
            ></button>
          </div>
        </div>
      </div> */}
      {confirmation &&
        createPortal(
          <div className="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-screen tw-z-50 tw-bg-black/50 tw-flex tw-items-center tw-justify-center">
            <div className="tw-rounded-md tw-p-8 tw-w-5/12 tw-bg-white">
              <div className="tw-bg-red-50 tw-border-l-8 tw-border-[#bf1736] tw-rounded-md tw-p-4 tw-text-[#bf1736] tw-mb-8">
                <p className="tw-flex tw-space-x-2 tw-items-center tw-text-[#bf1736] tw-mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="tw-w-4 tw-h-4 tw-flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                    />
                  </svg>
                  <span className="tw-font-bold">Alert</span>
                </p>
                <p className="tw-text-[#bf1736]">
                  You are trying to create a login for this user. This will send
                  an email to the user and the action is irreversible. Are you
                  sure you wish to continue?
                </p>
              </div>
              <div className="tw-flex tw-justify-end tw-space-x-4">
                <button
                  className="tw-px-4 tw-text-xs hover:tw-bg-black hover:tw-text-white tw-py-2 tw-rounded-md tw-bg-slate-100"
                  onClick={() => {
                    close_confirmation_handler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="tw-px-4 tw-min-w-[170px] tw-text-xs hover:tw-border-[#bf1736] hover:tw-bg-transparent hover:tw-text-[#bf1736] tw-border tw-border-transparent tw-text-white tw-py-2 tw-rounded-md tw-bg-[#bf1736]"
                  onClick={() => {
                    create_user_login();
                  }}
                >
                  {loading ? (
                    <svg
                      class="tw-animate-spin tw-h-5 tw-w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Send mail & create login"
                  )}
                </button>
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default CreateProfileButton;
