import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./styles.module.scss";
import { listApi } from "../../api/commonApis";
import { APIS } from "../../api/endpoints";
import ClientRow from "../../components/table/clientRow";
import SearchBar from "../../components/searchBar";
import Skeleton from "../../components/skeleton";
import Pagination from "../../components/table/pagination";
import DefaultSorting from "../../components/filters/defaultsorting";
import {
  AssAndOrgSortingOptions,
  NO_DATA_FOUND_LIST,
} from "../../constants/contants";
import { ROUTES } from "../../router/routes";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import SuggestionDropDown from "../../components/suggestionDropDown";
import CommonFilter from "components/commonFilter";
import { sidePanelAccess } from "utils/helper";
import NoListData from "components/table/noListData";
import { getState } from "redux/store";
import { useNavigate, useSearchParams } from "react-router-dom";

const defaultFilters = {
  director: [],
  locations: [],
  manager: [],
  sector: [],
  activeAssignments: false,
};

const Clients = () => {
  const [clientData, setClientData] = useState();
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);
  const [openDropDown, setOpenDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [filterHasValue, setFilterHasValue] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestionData, setSuggestionData] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [isSidePanel, setIsSidePanel] = useState(false);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropDown(false);
    }
  }, []);

  ///// To check whether  any filter is applied or not///////
  useEffect(() => {
    const cloneFilterData = cloneDeep(appliedFilters);
    setFilterHasValue(false);
    if (cloneFilterData.activeAssignments) {
      setFilterHasValue(true);
    }
    if (cloneFilterData.relocate) {
      setFilterHasValue(true);
    }
    delete cloneFilterData.activeAssignments;
    delete cloneFilterData.relocate;
    Object.keys(cloneFilterData).map((items) => {
      if (cloneFilterData[items].length > 0) {
        setFilterHasValue(true);
      }
    });
  }, [appliedFilters]);

  /*Reset the Page to First When Any FIlter is Applied */
  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [appliedFilters]);

  useEffect(() => {
    if (search !== "") {
      setOpenDropDown(true);
      document.addEventListener("click", handleClickOutside, true);
    }
    if (search === "") {
      setOpenDropDown(false);
      document.addEventListener("click", handleClickOutside, true);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [search]);

  useEffect(() => {
    setLoading(true);
    const payload = {
      search,
      skip: limitPerPage * page,
      limit: limitPerPage,
      activeAssignments: appliedFilters?.activeAssignments,
    };
    if (sorting) {
      payload.sort = sorting;
    }
    if (appliedFilters?.sector?.length > 0) {
      payload.sectors = JSON.stringify(appliedFilters?.sector);
    }
    if (appliedFilters?.locations?.length > 0) {
      payload.locations = JSON.stringify(appliedFilters?.locations);
    }
    if (appliedFilters?.director?.length > 0) {
      payload.director = JSON.stringify(appliedFilters?.director);
    }
    if (appliedFilters?.manager?.length > 0) {
      payload.manager = JSON.stringify(appliedFilters?.manager);
    }

    listApi(APIS.LIST_CLIENT, payload)
      .then((result) => {
        setClientData(result?.data?.data);
        setCount(result?.data?.count);
      })
      .finally(() => setLoading(false));
  }, [searchClicked, page, limitPerPage, appliedFilters, sorting]);

  /*suggestion API */

  useEffect(() => {
    listApi(APIS.SEARCH_SUGGESTION, {
      search: search ? search : "",
      type: 2,
    }).then((result) => {
      setSuggestionData(result?.data);
    });
  }, [search]);

  const SearchClick = () => {
    setSearchClicked((prev) => !prev);
  };

  const searchHandler = (e) => {
    e?.preventDefault();
    setSearch(e.target[0].value);
  };

  const onPageChange = (pageNo) => {
    setPage(pageNo);
  };

  const DefaultSortingHandler = (e) => {
    setSorting(e.value);
  };

  const searchTypeHandler = (e) => {
    if (e.target.value === "") {
      setSearch("");
      SearchClick();
    }
    setSearch(e.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(searchTypeHandler, 300),
    []
  );

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  // check if user is authorized to access this route
  const navigate = useNavigate();

  useEffect(() => {
    const current_user = getState()?.auth?.data;
    if (
      current_user.talentLabRole === "Organizations" ||
      current_user.talentLabRole === "Talent"
    ) {
      navigate("/");
    }
  }, []);

  return (
    <section
      className={`${styles.hasStyles} ${
        isSidePanel ? styles.overview : styles.centerOverview
      }`}
    >
      <div className="container">
        <SearchBar
          appliedFilters={appliedFilters}
          placeholder="organizations"
          onSubmit={searchHandler}
          onChange={debouncedChangeHandler}
          setInputValue={setInputValue}
          inputValue={inputValue}
          onFilterClick={() => setShowFilters((prev) => !prev)}
          SearchClick={SearchClick}
          resetValue={resetValue}
          setResetValue={setResetValue}
        />
        {openDropDown && (
          <div ref={dropdownRef}>
            <SuggestionDropDown
              key="client"
              dropdownData={suggestionData}
              onClick={(data) => {
                setOpenDropDown(false);
                window.open(
                  `${ROUTES.CLIENTS}${ROUTES.OVERVIEW}/${data?._id}?tab=overview`,
                  "_blank"
                );
              }}
            />
          </div>
        )}

        {showFilters && (
          <CommonFilter
            appliedFilters={appliedFilters}
            onChange={setAppliedFilters}
            isApplied={filterHasValue}
            onReset={() => setAppliedFilters(defaultFilters)}
            type={3}
          />
        )}

        <div className="row">
          <div className="col-md-12"></div>
        </div>

        <div className="pt-2 mt-3 row">
          <div className="col-md-12">
            <div
              className={`d-flex align-items-center justify-content-between ${styles.showingresult}`}
            >
              <div
                className={`d-flex align-items-center ${styles.showingleft}`}
              >
                <p className="mb-0 me-3">
                  Showing <strong> {count} </strong> results
                </p>
              </div>
              <div
                className={`d-flex align-items-center ${styles.showingright}`}
              >
                <DefaultSorting
                  fontSize="12px"
                  type="select"
                  value={AssAndOrgSortingOptions.filter(
                    (i) => i.value === sorting
                  )}
                  onChange={DefaultSortingHandler}
                  options={AssAndOrgSortingOptions}
                  placeholder={"Default Sorting"}
                  name={"sorting"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 row">
          {loading ? (
            Array.from(Array(limitPerPage).keys())?.map((i, index) => (
              <Skeleton height="258px" key={index} marginBottom="15px" />
            ))
          ) : (
            <div className="col-md-12">
              {clientData?.length === 0 ? (
                <NoListData label={NO_DATA_FOUND_LIST.ORG_LIST} />
              ) : (
                clientData?.map((data, index) => {
                  return <ClientRow key={index} data={data} />;
                })
              )}
            </div>
          )}
          {clientData?.length !== 0 && (
            <Pagination
              totalCount={count}
              currentPage={page}
              limitPerPage={limitPerPage}
              onPageChange={onPageChange}
              setLimitPerPage={setLimitPerPage}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Clients;
