import React, { useState } from "react";
import styles from "./styles.module.scss";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Editor } from "@tinymce/tinymce-react";
import { editorApiKey, editorSettings } from "../../../constants/contants";

const FormInput = ({
  name,
  label,
  placeholder,
  type,
  touched = false,
  error = false,
  maxLength,
  bottomError,
  defaultValue,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: error ? "#bf1736" : provided?.borderColor,
      fontSize: "12px",
      fontWeight: "500",
      paddingLeft: "5px",
      boxShadow: "none",
      "&:focus-within": {
        borderColor: "#011cf5!important",
      },
      "&:focus-visible": {
        borderColor: "#011cf5!important",
      },
      "&:focus": {
        borderColor: "#011cf5!important",
      },
      "&:hover": {
        borderColor: "#011cf5!important",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWeight: "500",
    }),
  };

  return (
    <div className={styles.formgroup}>
      <div className={styles.labelContainer}>
        <label className={`${styles.label} ${focus ? styles.focusLabel : ""}`}>
          {label}
        </label>
        {!bottomError && error && (
          <span className={styles.errorText}>{error}</span>
        )}
      </div>

      {type === "select" ? (
        <Select
          defaultValue={defaultValue}
          styles={selectStyles}
          name={name}
          placeholder={placeholder}
          onFocus={() => setFocus(true)}
          onBlur={(e) => {
            setFocus(false);
            rest?.onBlur?.(e);
          }}
          {...rest}
        />
      ) : type === "async-select" ? (
        <AsyncSelect
          styles={selectStyles}
          name={name}
          placeholder={placeholder}
          onFocus={() => setFocus(true)}
          onBlur={(e) => {
            setFocus(false);
            rest?.onBlur?.(e);
          }}
          {...rest}
        />
      ) : type === "textarea" ? (
        <textarea
          rows="4"
          cols="50"
          name={name}
          placeholder={placeholder}
          className={`${styles.textarea} ${error ? styles.error : ""}`}
          onFocus={() => setFocus(true)}
          {...rest}
          onBlur={(e) => {
            setFocus(false);
            rest?.onBlur(e);
          }}
        />
      ) : type === "editor" ? (
        <Editor
          apiKey={editorApiKey}
          init={editorSettings}
          onFocus={() => setFocus(true)}
          onBlur={(e) => {
            setFocus(false);
            rest?.onBlur?.(e);
          }}
          {...rest}
        />
      ) : type === "reminderInput" ? (
        <input
          name={name}
          type={type}
          onFocus={() => setFocus(true)}
          placeholder={placeholder}
          className={`${styles.joiningDate} ${
            error ? styles.joiningDateError : ""
          }`}
          {...rest}
          onBlur={(e) => {
            setFocus(false);

            rest?.onBlur?.(e);
          }}
        />
      ) : type === "password" ? (
        <input
          name={name}
          type={type}
          onFocus={() => setFocus(true)}
          placeholder={placeholder}
          className={`${styles.input} ${error ? styles.error : ""}`}
          {...rest}
          onBlur={(e) => {
            setFocus(false);

            rest?.onBlur?.(e);
          }}
        />
      ) : (
        <input
          name={name}
          maxLength={maxLength ? maxLength : ""}
          type={type}
          onFocus={() => setFocus(true)}
          placeholder={placeholder}
          className={`${styles.input} ${error ? styles.error : ""}`}
          {...rest}
          onBlur={(e) => {
            setFocus(false);

            rest?.onBlur?.(e);
          }}
        />
      )}
      {bottomError && error && (
        <span className={` text-end ${styles.errorText}`}>{error}</span>
      )}
    </div>
  );
};

export default FormInput;
