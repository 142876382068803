import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./styles.module.scss";
import { listApi } from "../../api/commonApis";
import { APIS } from "../../api/endpoints";
import TalentRow from "../../components/table/talentRow";
import ClientRow from "../../components/table/clientRow";
import AssignmentRow from "../../components/table/assignmentRow";
import SearchBar from "../../components/searchBar";
import SearchFilters from "./searchFilters";
import Skeleton from "../../components/skeleton";
import Pagination from "../../components/table/pagination";
import DefaultSorting from "../../components/filters/defaultsorting";
import { sortingOptions } from "../../constants/contants";
import { AssAndOrgSortingOptions } from "../../constants/contants";
import { SEARCH_TAB_TYPE } from "../../constants/contants";
import cloneDeep from "lodash/cloneDeep";
import { ROUTES } from "../../router/routes";
import debounce from "lodash/debounce";
import SearchSuggestion from "./searchSuggestion";
import { hasPermission, pageTypeHandler } from "utils/helper";
import { MODULES, PERMISSIONS, SUB_MODULES } from "constants/accessControl";
import { sidePanelAccess, headerPermission } from "utils/helper";
import { useNavigate } from "react-router-dom";
import { getState } from "redux/store";

const defaultFilters = {
  locations: [],
  educations: [],
  sectors: [],
  relocate: false,
  gender: [],
  designation: [],
  experience: [],
  compensation: [],
  competency: [],
  director: [],
  manager: [],
  activeAssignments: false,
  company: [],
  consultant: [],
  functionalAreas: [],
  sector: [], //client Sector
};

const Search = () => {
  const [rowData, setRowData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);
  const [activeTab, setActiveTab] = useState(SEARCH_TAB_TYPE.talent);
  const [selectOptions, setSelectOptions] = useState(sortingOptions);
  const [openDropDown, setOpenDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [onFocus, setOnFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filterHasValue, setFilterHasValue] = useState(false);
  const [suggestionData, setSuggestionData] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [hasAssWriteAccess, setHasAssWriteAccess] = useState(false);
  const [isSidePanel, setIsSidePanel] = useState(false);

  ///// TAB VIEW/////////
  const SEARCH_TAB = [
    {
      name: "Talent",
      type: SEARCH_TAB_TYPE.talent,
      hasAcces: headerPermission(MODULES.TALENT),
    },
    {
      name: "Organization",
      type: SEARCH_TAB_TYPE.organisation,
      hasAcces: headerPermission(MODULES.ORGANIZATIONS),
    },
    {
      name: "Assignment",
      type: SEARCH_TAB_TYPE.assignment,
      hasAcces: headerPermission(MODULES.ASSIGNMENT),
    },
  ];

  //////// PERMISSISON///////
  useEffect(() => {
    setHasAssWriteAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_OVERVIEW,
        PERMISSIONS.WRITE
      )
    );
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropDown(false);
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setOpenDropDown(true);
      document.addEventListener("click", handleClickOutside, true);
    }
    if (search === "") {
      setOpenDropDown(false);
      document.addEventListener("click", handleClickOutside, true);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [search]);

  useEffect(() => {
    setLoading(true);
    const payload = {
      search,
      skip: limitPerPage * page,
      limit: limitPerPage,
    };

    let apiEndPoint;

    if (activeTab === SEARCH_TAB_TYPE.talent) {
      apiEndPoint = APIS.LIST_TALENTS;
      if (appliedFilters?.relocate) {
        payload.relocate = appliedFilters?.relocate;
      }

      if (appliedFilters?.experience) {
        const allYears = [...appliedFilters?.experience.flat()];
        if (allYears.length) {
          const minExperience = Math.min(...allYears);
          const maxExperience = Math.max(...allYears);

          payload.experience = {
            minExperience,
            maxExperience,
          };
        }
      }
      if (appliedFilters?.competency?.length > 0) {
        const competencyObj = {
          minCompetency: JSON.stringify(appliedFilters?.competency[0]),
          maxCompetency: JSON.stringify(appliedFilters?.competency[1]),
        };
        payload.competency = competencyObj;
      }

      if (appliedFilters?.compensation?.length > 0) {
        const compensationObj = {
          minCompensation: JSON.stringify(appliedFilters?.compensation[0]),
          maxCompensation: JSON.stringify(appliedFilters?.compensation[1]),
        };
        payload.compensation = compensationObj;
      }

      if (appliedFilters?.gender?.length) {
        payload.gender = JSON.stringify(appliedFilters?.gender);
      }
      if (appliedFilters?.locations?.length > 0) {
        payload.locations = JSON.stringify(appliedFilters?.locations);
      }
      if (appliedFilters?.sectors?.length > 0) {
        payload.sectors = JSON.stringify(appliedFilters?.sectors);
      }
      if (appliedFilters?.educations?.length > 0) {
        payload.educations = JSON.stringify(appliedFilters?.educations);
      }
      if (appliedFilters?.functionalAreas?.length > 0) {
        payload.functionalAreas = JSON.stringify(
          appliedFilters?.functionalAreas
        );
      }
      if (appliedFilters?.designation?.length > 0) {
        payload.designation = JSON.stringify(appliedFilters?.designation);
      }
      if (sorting) {
        payload.sort = sorting;
      }
    } else if (activeTab === SEARCH_TAB_TYPE.organisation) {
      apiEndPoint = APIS.LIST_CLIENT;
      payload.activeAssignments = appliedFilters?.activeAssignments;

      if (sorting) {
        payload.sort = sorting;
      }
      if (appliedFilters?.sector?.length > 0) {
        payload.sectors = JSON.stringify(appliedFilters?.sector);
      }
      if (appliedFilters?.locations?.length > 0) {
        payload.locations = JSON.stringify(appliedFilters?.locations);
      }
      if (appliedFilters?.director?.length > 0) {
        payload.director = JSON.stringify(appliedFilters?.director);
      }
      if (appliedFilters?.manager?.length > 0) {
        payload.manager = JSON.stringify(appliedFilters?.manager);
      }
    } else {
      apiEndPoint = APIS.LIST_ASSIGNMENT;

      if (appliedFilters?.company?.length > 0) {
        payload.clients = JSON.stringify(appliedFilters?.company);
      }
      if (appliedFilters?.locations?.length > 0) {
        payload.locations = JSON.stringify(appliedFilters?.locations);
      }
      if (appliedFilters?.director?.length > 0) {
        payload.director = JSON.stringify(appliedFilters?.director);
      }
      if (appliedFilters?.consultant?.length > 0) {
        payload.consultant = JSON.stringify(appliedFilters?.consultant);
      }
      if (sorting) {
        payload.sort = sorting;
      }
    }
    listApi(apiEndPoint, payload)
      .then((result) => {
        setRowData(result?.data?.data);
        setCount(result?.data?.count);
      })
      .finally(() => setLoading(false));
  }, [searchClicked, page, sorting, appliedFilters, limitPerPage, activeTab]);

  useEffect(() => {
    listApi(APIS.SEARCH_SUGGESTION, {
      search: search ? search : "",
      type: activeTab,
    }).then((result) => {
      setSuggestionData(result?.data);
    });
  }, [search]);

  const SearchClick = () => {
    setSearchClicked((prev) => !prev);
  };

  const searchHandler = (e) => {
    e?.preventDefault();
    setSearch(e.target[0].value);
  };

  const DefaultSortingHandler = (e) => {
    setSorting(e.value);
  };
  const onPageChange = (pageNo) => {
    setPage(pageNo);
  };

  const RowComponent = (props) => {
    switch (activeTab) {
      case SEARCH_TAB_TYPE.talent:
        return <TalentRow {...props} />;
      case SEARCH_TAB_TYPE.organisation:
        return <ClientRow {...props} />;
      case SEARCH_TAB_TYPE.assignment:
        return (
          <AssignmentRow
            {...props}
            activetab="open-assignments"
            hasAssWriteAccess={hasAssWriteAccess}
          />
        );
      default:
        return <TalentRow {...props} />;
    }
  };

  ///// To check whether  any filter is applied or not///////
  useEffect(() => {
    const cloneFilterData = cloneDeep(appliedFilters);
    setFilterHasValue(false);
    if (cloneFilterData.activeAssignments) {
      setFilterHasValue(true);
    }
    if (cloneFilterData.relocate) {
      setFilterHasValue(true);
    }
    delete cloneFilterData.activeAssignments;
    delete cloneFilterData.relocate;
    Object.keys(cloneFilterData).map((items) => {
      if (cloneFilterData[items].length > 0) {
        setFilterHasValue(true);
      }
    });
  }, [appliedFilters]);

  /*Reset the Page to First When Any FIlter is Applied */

  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [appliedFilters]);

  useEffect(() => {
    setSearch("");
    setInputValue("");

    if (activeTab === SEARCH_TAB_TYPE.talent) {
      setSelectOptions(sortingOptions);
    } else if (activeTab === SEARCH_TAB_TYPE.organisation) {
      setSelectOptions(AssAndOrgSortingOptions);
    } else if (activeTab === SEARCH_TAB_TYPE.assignment) {
      setSelectOptions(AssAndOrgSortingOptions);
    }
  }, [activeTab]);

  const changeTab = (newTab) => {
    if (newTab !== activeTab) {
      setRowData([]);
      setAppliedFilters(defaultFilters);
      setSorting(1);
      setActiveTab(newTab);
    }
  };

  const searchTypeHandler = (e) => {
    if (e.target.value === "") {
      setSearch("");
      SearchClick();
    }
    setSearch(e.target.value);
  };

  const debouncedChangeHandler = useCallback(
    debounce(searchTypeHandler, 300),
    []
  );

  /* search Click Navigation Handler */

  const searchNavigationHanlder = (data) => {
    if (activeTab === 1) {
      window.open(
        `${ROUTES.TALENT}${ROUTES.OVERVIEW}/${data?._id}?tab=overview`,
        "_blank"
      );
    }
    if (activeTab === 2) {
      window.open(
        `${ROUTES.CLIENTS}${ROUTES.OVERVIEW}/${data?._id}?tab=overview`,
        "_blank"
      );
    }
    if (activeTab === 3) {
      window.open(
        `${ROUTES.ASSIGNMENTS}${ROUTES.OVERVIEW}/${data?._id}?tab=overview&activetab=open-assignments`,
        "_blank"
      );
    }
  };

  // HANDLER WHEN CLICK ON THE SUGGESTION ITEM

  const suggestionClickHandler = (data) => {
    setOpenDropDown(false);
    searchNavigationHanlder(data);
  };

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  // check if user is authorized to access this route
  const navigate = useNavigate();
  useEffect(() => {
    const current_user = getState()?.auth?.data;

    if (
      current_user.talentLabRole === "Organizations" ||
      current_user.talentLabRole === "Talent"
    ) {
      navigate("/");
    }
  }, []);

  return (
    <section
      className={`${styles.hasStyles} ${
        isSidePanel ? styles.overview : styles.centerOverview
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={styles.test}>
              <ul className={`nav nav-tabs ${styles.tabColumn}`}>
                {SEARCH_TAB.map(
                  (tab, index) =>
                    tab?.hasAcces && (
                      <li className={`nav-item ${styles.navItem} `}>
                        <a
                          className={`nav-link  ${
                            activeTab === tab?.type
                              ? `active ${styles.activeButtonCss} 
                       ${onFocus && styles.activeTabStyles} 

                        `
                              : styles.inActiveButton
                          } `}
                          aria-current="page"
                          href="#"
                          onClick={() => {
                            changeTab(tab?.type);
                            setSearch("");
                            setResetValue(false);
                          }}
                        >
                          {tab?.name}
                        </a>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* SearchBar */}

        <SearchBar
          resetValue={resetValue}
          setResetValue={setResetValue}
          appliedFilters={appliedFilters}
          multiSearch
          setInputValue={setInputValue}
          inputValue={inputValue}
          onSubmit={searchHandler}
          onChange={debouncedChangeHandler}
          onSearchFocus={(isFocus) => setOnFocus(isFocus)}
          onFilterClick={() => setShowFilters((prev) => !prev)}
          SearchClick={SearchClick}
        />

        {/* Suggestion Drop Down  */}

        {openDropDown && (
          <div ref={dropdownRef}>
            <SearchSuggestion
              rowData={rowData}
              onClick={suggestionClickHandler}
              suggestionData={suggestionData}
            />
          </div>
        )}

        {/* FILTER */}

        {showFilters && (
          <SearchFilters
            type={pageTypeHandler(activeTab)}
            activeTab={activeTab}
            appliedFilters={appliedFilters}
            onChange={setAppliedFilters}
            isApplied={filterHasValue}
            onReset={() => setAppliedFilters(defaultFilters)}
          />
        )}

        <div className="mt-4 row">
          <div className="col-md-12">
            <div
              className={`d-flex align-items-center justify-content-between ${styles.showingresult}`}
            >
              <div
                className={`d-flex align-items-center ${styles.showingleft}`}
              >
                <p className="mb-0 me-3">
                  Showing <strong> {count} </strong> results
                </p>
              </div>
              <div
                className={`d-flex align-items-center ${styles.showingright}`}
              >
                <div className={styles.defaultSelect}>
                  <DefaultSorting
                    fontSize="12px"
                    type="select"
                    onChange={DefaultSortingHandler}
                    value={selectOptions?.filter((i) => i.value === sorting)}
                    options={selectOptions}
                    placeholder={"Default Sorting"}
                    name={"sorting"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 row">
          {loading ? (
            Array.from(Array(limitPerPage).keys())?.map((i, index) => (
              <Skeleton height="65px" key={index} marginBottom="15px" />
            ))
          ) : (
            <div className="col-md-12">
              {rowData?.map((data, index) => (
                <RowComponent key={index} data={data} />
              ))}
              {rowData?.length !== 0 && (
                <Pagination
                  totalCount={count}
                  currentPage={page}
                  limitPerPage={limitPerPage}
                  onPageChange={onPageChange}
                  setLimitPerPage={setLimitPerPage}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Search;
