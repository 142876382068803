import React from "react";
import "./index.css";
import styles from "./styles.module.scss";
import { IMAGES } from "../../../constants/assets";
import { MODULES, PERMISSIONS, SUB_MODULES } from "constants/accessControl";
import { hasPermission } from "utils/helper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const ProfileSideMenu = ({ items, isActive, onClick, dataSetsOptions }) => {
  const menu = [
    { label: "Profile", id: "profiledetails", hasAccess: true, children: [] },
    { label: "Calendar", id: "calendar", hasAccess: true, children: [] },
    {
      label: "Change Password",
      id: "changepassword",
      hasAccess: true,
      children: [],
    },
    {
      label: "Assignments",
      id: "assignments",
      hasAccess: true,
      children: [],
    },
    // {
    //   label: "TalentLab Users",
    //   id: "talentlabusers",
    //   hasAccess: hasPermission(
    //     MODULES.DATA_SETS,
    //     SUB_MODULES.USER_MANAGMENT,
    //     PERMISSIONS.READ
    //   ),
    // },
    {
      label: "Users",
      id: "tl-admins",
      hasAccess: hasPermission(
        MODULES.DATA_SETS,
        SUB_MODULES.USER_MANAGMENT,
        PERMISSIONS.READ
      ),
      sub_menu_id: "users",
      children: [
        {
          label: "TalentLab Admins",
          id: "tl-admins",
          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.USER_MANAGMENT,
            PERMISSIONS.READ
          ),
        },
        {
          label: "Talent Users",
          id: "tl-talent-users",
          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.USER_MANAGMENT,
            PERMISSIONS.READ
          ),
        },
        {
          label: "Talent Profiles",
          id: "tl-talents",
          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.USER_MANAGMENT,
            PERMISSIONS.READ
          ),
        },
        {
          label: "Organizations",
          id: "tl-orgs",

          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.USER_MANAGMENT,
            PERMISSIONS.READ
          ),
        },
      ],
    },
    {
      label: "Data Sets",
      id: "datasets-industrytype",
      sub_menu_id: "datasets",
      hasAccess: true,
      children: [
        {
          label: "Industry Types",
          id: "datasets-industrytype",
          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.INDUSTRY_TYPE,
            PERMISSIONS.READ
          ),
        },
        {
          label: "Sectors",
          id: "datasets-sectors",
          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.SECTORS,
            PERMISSIONS.READ
          ),
        },
        {
          label: "Functional Areas",
          id: "datasets-functionalareas",
          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.FUNCTIONAL_AREAS,
            PERMISSIONS.READ
          ),
        },
        {
          label: "Skills/Tags",
          id: "datasets-skillstags",
          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.SKILLS_TAGS,
            PERMISSIONS.READ
          ),
        },
        {
          label: "Competencies",
          id: "datasets-compentency",
          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.COMPETENCIES,
            PERMISSIONS.READ
          ),
        },
        {
          label: "Education Types",
          id: "datasets-education",
          hasAccess: hasPermission(
            MODULES.DATA_SETS,
            SUB_MODULES.EDUCATION_TYPE,
            PERMISSIONS.READ
          ),
        },

        {
          label: "App Shortcuts",
          id: "app-shortcuts",
          hasAccess: true,
          children: [],
        },
      ],
    },
    {
      label: "Download Shortcuts",
      id: "tl-shortcuts",
      hasAccess: true,
      sub_menu_id: "tl-shortcuts",
      children: [],
    },
  ];

  const [params, setParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const tab = params?.get?.("tab");
  const _mid = params?.get?.("mid");

  const [sub_menu, set_sub_menu] = useState("");

  const show_child_menu = (id) => {
    set_sub_menu(id);
  };

  useEffect(() => {
    if (tab.includes("tl-") || _mid?.includes("tl-")) {
      set_sub_menu("users");
    }

    if (tab.includes("datasets")) {
      set_sub_menu("datasets");
    }
  }, [tab, _mid]);

  return (
    <div className="">
      {menu // filtering basis access
        .filter((m) => m.hasAccess)
        .map((nav) => {
          return (
            <div key={menu.id} className={`tw-text-[12px] tw-mt-4 tw-px-5 `}>
              <div className="">
                {nav.children.length <= 0 ? (
                  <a
                    className={`tw-text-black tw-flex tw-items-center tw-space-x-2 ${
                      tab === nav.id ? "tw-text-[#011fc5]" : ""
                    }`}
                    target={`${nav.id === "calendar" ? "_blank" : "_self"}`}
                    href={`${
                      nav.id === "calendar"
                        ? "https://calendar.google.com/calendar"
                        : "/profile?tab=" + nav.id
                    }`}
                  >
                    <span>{nav.label}</span>
                  </a>
                ) : (
                  <button
                    className={`tw-flex tw-items-center tw-space-x-2 tw-bg-transparent ${
                      tab == nav.id ? "tw-text-[#011fc5]" : "tw-text-black "
                    }`}
                    onClick={() => {
                      show_child_menu(nav.sub_menu_id);
                    }}
                  >
                    <span>{nav.label}</span>

                    <span>
                      {nav.children.length > 0 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="tw-w-3 tw-h-3"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      )}
                    </span>
                  </button>
                )}

                <div
                  className={`${
                    sub_menu === nav.sub_menu_id
                      ? `${
                          sub_menu === "datasets"
                            ? "tw-h-[94px]"
                            : "tw-h-[76px]"
                        } tw-overflow-hidden`
                      : "tw-h-0 tw-overflow-hidden"
                  } flux`}
                >
                  {nav.children.map((child) => {
                    return (
                      <div key={child.id} className="tw-text-[10px] tw-pt-1 ">
                        <a
                          className={` tw-flex tw-items-center tw-space-x-2
                      ${
                        tab == child.id || _mid?.includes(child?.id)
                          ? "tw-text-[#011fc5]"
                          : "tw-text-slate-400"
                      }
                      `}
                          href={`/profile?tab=${child.id}`}
                        >
                          <span>{child.label}</span>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
    // <div
    //   className={`flex-column  ${
    //     dataSetsOptions ? styles.dataSetsStyle : styles.sideMenu
    //   }`}
    // >
    //   {items.map(
    //     (menu, index) =>
    //       menu?.hasAccess && (
    //         <div
    //           className={`nav-link ${styles.navlink} ${
    //             isActive?.(menu) ? styles.active : ""
    //           }`}
    //           key={index}
    //           onClick={() => onClick(menu)}
    //         >
    //           <div className="d-flex">
    //             {menu.label}
    //             {!!menu.id === "calendar" && (
    //               <img
    //                 src={
    //                   isActive?.(menu)
    //                     ? IMAGES.EXTERNAL_LINK
    //                     : IMAGES.PROFILE_EXTERNAL_LINK
    //                 }
    //                 className={styles.imageIcon}
    //               />
    //             )}
    //           </div>
    //         </div>
    //       )
    //   )}
    // </div>
  );
};
export default ProfileSideMenu;
