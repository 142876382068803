import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BASE_URL } from "../../api";
import { ROUTES } from "../../router/routes";
import { useEffect } from "react";
import { getState } from "redux/store";

const DocViewer = () => {
  const { id } = useParams();
  const isLoggedIn = useSelector((state) => state?.auth?.data?.accessToken);

  // check if user is authorized to access this route
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const current_user = getState()?.auth?.data;

    const for_id = searchParams.get("fid");
    const by_id = searchParams.get("bid");

    // console.log({
    //   r: current_user.talentLabRole,
    //   f: current_user.commonId !== for_id,
    //   b: current_user?._id !== by_id,
    //   c: current_user,
    //   bi: by_id,
    //   fi: for_id,
    // });

    if (
      current_user.talentLabRole === "Organizations" ||
      current_user.talentLabRole === "Talent"
    ) {
      if (current_user?.commonId !== for_id && current_user?._id !== by_id) {
        navigate("/");
      }
    }

    // if (current_user.talentLabRole === "Talent") {
    //   if (current_user.commonId !== id) {
    //     navigate("/");
    //   }
    // }
  }, []);

  if (isLoggedIn) {
    return (
      <iframe
        title="doc"
        src={`${BASE_URL}/bucket/${id}`}
        style={{ width: "100%", height: "100vh" }}
      >
        Presss me: <a href="./resources/crayola.pdf">Download PDF</a>
      </iframe>
    );
  }

  return <Navigate to={ROUTES.LOGIN} replace />;
};

export default DocViewer;
