import { APIS } from "api";
import { listApi } from "api/commonApis";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import SendInvite from "./SendInvite";
import InvitationDetails from "./InvitationDetails";

const InvitationHandler = ({ organisation }) => {
  const [invitations, set_invitations] = useState([]);
  const [has_accepted_invite, set_has_accepted_invite] = useState([]);

  useEffect(() => {
    listApi(APIS.FETCH_INVITE_DETAILS, {
      id: organisation?._id,
    }).then((result) => {
      // set invitations
      set_invitations(result.data);
      console.log(result.data);

      // check if the invitation has been accepted
      const acceptance = result.data.some((i) => i.invitation_accepted);
      set_has_accepted_invite(acceptance);
    });
  }, [organisation]);

  return (
    <>
      {/* {!has_accepted_invite && (
        <div className="tw-flex tw-items-start tw-space-x-4">
          <SendInvite organisation={organisation}></SendInvite>
          <InvitationDetails invitations={invitations}></InvitationDetails>
        </div>
      )} */}

      <button
        onClick={() => {
          window.location.href = `/profile?tab=team-members&id=${organisation?._id}&mid=tl-orgs-team-members`;
        }}
        className="lg:hover:tw-bg-[#011fc5] tw-text-xs lg:tw-bg-[#f4f4f4] tw-shadow-sm lg:tw-text-black flux lg:hover:tw-text-white tw-px-3 tw-py-2 tw-rounded-md "
      >
        Manage Team
      </button>
    </>
  );
};

export default InvitationHandler;
