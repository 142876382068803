import { SkipNext } from "@mui/icons-material";
import { APIS } from "api";
import { listApi } from "api/commonApis";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const Members = ({ id }) => {
  const [admin_list, setAdminList] = useState();
  const [count, setCount] = useState(0);

  useEffect(() => {
    let payload = {
      talentLabRole: "Organizations",
      limit: 99999,
      skip: 0,
    };

    listApi(APIS.FETCH_INVITE_DETAILS, {
      id: id,
    }).then((result) => {
      const org_data = result?.data?.filter(
        (i) => i?.invitation_accepted && i?.conversion_id?.isDeleted !== true
      );
      setAdminList(org_data);
      setCount(org_data.length);
    });
  }, [id]);

  return <div>{count}</div>;
};

export default Members;
