import React from "react";
import "./index.css";
import { useState } from "react";
import { createPortal } from "react-dom";
import { APIS } from "api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { addEditApi, listApi } from "api/commonApis";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ToastMessage from "components/layout/toast";

const EmailInvoices = ({ invoice_data }) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [params] = useSearchParams();

  const [searchParams] = useSearchParams();

  //   const contactId = searchParams.get("id");

  const [billing_list, set_billing_list] = useState(false);

  const [loading, set_loading] = useState(true);

  const [billing_contact_details, set_billing_contact_details] = useState([]);

  const [_contact, set_contact] = useState({});

  const load_billing_details = () => {
    listApi(APIS.LIST_CLIENT_BILLING, {
      clientId: id,
    }).then((result) => {
      set_billing_contact_details([...result?.data?.data]);
    });
  };

  const send_email_invoice = ({ invoice_data, contact }) => {
    set_loading(true);
    addEditApi(APIS.SEND_INVOICE_EMAIL, {
      invoice_data,
      contact,
    })
      .then((result) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={`Invoice has been successfully sent to ${contact.billingContact.name}`}
          />
        );
      })
      .finally(() => {
        set_billing_list(false);
        set_loading(false);
        set_contact({});
      });
  };

  //   useEffect(() => {
  //     //   setLoading(true);
  //     listApi(APIS.LIST_CLIENT_BILLING, {
  //       clientId: id,
  //     }).then((result) => {
  //       set_billing_contact_details([...result?.data?.data]);
  //     });
  //   }, []);

  return (
    <div>
      <div>
        <button
          onClick={() => {
            load_billing_details();
            set_billing_list(true);
          }}
          className="tw-bg-transparent -tw-mt-[3px] tw-mr-1 tw-p-0 tw-block "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17}
            height={17}
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="#011fc5"
              strokeLinecap="round"
              strokeWidth="1.5"
            >
              <path
                strokeLinejoin="round"
                d="m9 9l4.5 3L18 9M3 13.5h2m-4-3h4"
              ></path>
              <path d="M5 7.5V7a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-.5"></path>
            </g>
          </svg>
        </button>
      </div>

      {billing_list &&
        createPortal(
          <div
            onClick={() => {
              set_billing_list(false);
            }}
            className="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-screen tw-z-50 tw-bg-black/50 tw-flex tw-items-center tw-justify-center"
          >
            <div className="tw-rounded-md tw-p-8 tw-w-5/12 tw-bg-white">
              <div className="tw-rounded-md  tw-bg-white tw-text-slate-400 tw-text-[11px]">
                {billing_contact_details.length <= 0 && (
                  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-4">
                    <div>
                      <p className="tw-font-bold tw-text-black tw-m-0">
                        Email invoice
                      </p>
                      <p className="tw-text-[#6b7280] tw-m-0">
                        To enable this feature, add the organization's billing
                        detail in the billing panel along with the email ID to
                        which the email needs to be sent to.
                      </p>
                    </div>

                    <a
                      className=" !tw-text-white hover:!tw-text-white tw-rounded-md tw-justify-center tw-px-4 tw-py-2 tw-bg-[#011cf5] hover:!tw-opacity-50 tw-flex tw-space-x-2 tw-items-center"
                      href={`/clients/billing/${id}?source=overview`}
                    >
                      <span>Add Billing details</span>
                    </a>
                  </div>
                )}
                {billing_contact_details.length > 0 && (
                  <div>
                    <p className="tw-font-bold tw-text-black">
                      Billing Contacts
                    </p>
                    <table className="tw-mb-8 tw-table-fixed tw-w-full">
                      <thead>
                        <tr>
                          <th className="tw-p-2">Name</th>
                          <th className="tw-p-2">Email</th>
                          <th className="tw-p-2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {billing_contact_details.map((contact) => {
                          return (
                            <tr key={contact?._id}>
                              <td className="tw-p-2 tw-border-b tw-border-slate-100">
                                {contact?.billingContact?.name}
                              </td>
                              <td className="tw-p-2 tw-border-b tw-border-slate-100">
                                {contact?.billingContact?.email}
                              </td>
                              <td className="tw-p-2 tw-border-b tw-border-slate-100">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    console.table({ invoice_data, contact });
                                    send_email_invoice({
                                      invoice_data,
                                      contact,
                                    });
                                    set_contact(contact);
                                  }}
                                  className="tw-px-3 tw-min-w-[170px] tw-flex tw-items-center tw-justify-center tw-space-x-1 tw-text-[11px] hover:tw-border-[#011f5c] hover:tw-bg-transparent hover:tw-text-[#011f5c] tw-border tw-border-transparent tw-text-white tw-py-1 tw-rounded-md tw-bg-[#011fc5]"
                                >
                                  {loading && _contact._id == contact._id ? (
                                    <span>
                                      {" "}
                                      <svg
                                        class="tw-animate-spin tw-h-4 tw-w-4 tw-mr-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <circle
                                          class="opacity-25"
                                          cx="12"
                                          cy="12"
                                          r="10"
                                          stroke="currentColor"
                                          stroke-width="4"
                                        ></circle>
                                        <path
                                          class="opacity-75"
                                          fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                      </svg>{" "}
                                      <span>Sending...</span>
                                    </span>
                                  ) : (
                                    <span>
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={16}
                                          height={16}
                                          className="-tw-mt-0.5"
                                          viewBox="0 0 24 24"
                                        >
                                          <g
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeWidth="1.5"
                                          >
                                            <path
                                              strokeLinejoin="round"
                                              d="m9 9l4.5 3L18 9M3 13.5h2m-4-3h4"
                                            ></path>
                                            <path d="M5 7.5V7a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-.5"></path>
                                          </g>
                                        </svg>
                                      </span>{" "}
                                      <span>Send invoice mail</span>
                                    </span>
                                  )}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              {/* <div className="tw-flex tw-justify-end tw-space-x-4">
                <button
                  onClick={() => {
                    set_billing_list(false);
                  }}
                  className="tw-px-4 tw-text-xs hover:tw-bg-black hover:tw-text-white tw-py-2 tw-rounded-md tw-bg-slate-100"
                >
                  Cancel
                </button>
                <button className="tw-px-4 tw-min-w-[170px] tw-text-xs hover:tw-border-[#bf1736] hover:tw-bg-transparent hover:tw-text-[#bf1736] tw-border tw-border-transparent tw-text-white tw-py-2 tw-rounded-md tw-bg-[#bf1736]">
                  Send mail & create login
                </button>
              </div> */}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default EmailInvoices;
