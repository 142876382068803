import { getState } from "redux/store";
import { axiosInstance } from "./client";
import { addEditApi, listApi } from "./commonApis";
import { APIS } from "./endpoints";

const fetch_entity = async (entity, id, user_id) => {
  if (entity === "Talent") {
    await listApi(APIS.LIST_TALENT_OVERVIEW, { talentId: id }).then(
      async (result) => {
        console.log(result?.data, "fetch_entity talent");
        const data = await result?.data[0];
        if (
          data?.selfSignUp &&
          data?.has_skiped_walkthrough === false &&
          (data?.attempt <= 5 || data?.attempt === undefined)
        ) {
          await addEditApi(APIS.ADD_EDIT_TALENT_PROFILE, {
            talentId: id,
            has_taken_walkthrough: false,
            has_skiped_walkthrough: false,
            walthrough_timestamp: new Date(),
            attempt: data?.attempt !== undefined ? data?.attempt : 1,
          }).then(async (response) => {
            await console.log(response, "turn_on_walkthrough candidate");
          });
        }
      }
    );
  }

  if (entity === "Organizations") {
    await listApi(APIS.LIST_ADMIN, { id: user_id }).then(async (result) => {
      console.log(result?.data.data, "fetch_entity client");
      const data = await result?.data.data[0];
      if (
        data?.selfSignUp &&
        data?.has_skiped_walkthrough === false &&
        (data?.attempt <= 5 || data?.attempt === undefined)
      ) {
        const fdata = new FormData();
        fdata.append("adminId", data._id);
        fdata.append("has_taken_walkthrough", false);
        fdata.append("has_skiped_walkthrough", false);
        fdata.append("walthrough_timestamp", new Date());
        fdata.append(
          "attempt",
          data?.attempt !== undefined ? data?.attempt : 1
        );

        await addEditApi(APIS.ADD_EDIT_ADMINS, fdata).then(async (response) => {
          await console.log(response, "turn_on_walkthrough client");
        });
      }
    });
  }
};

export const login = async (payload) => {
  return axiosInstance()
    .post(APIS.LOGIN, payload)
    .then(async (response) => {
      console.log(response, "response login");

      let entity;

      setTimeout(() => {
        entity = fetch_entity(
          response?.data?.data?.talentLabRole,
          response?.data?.data?.commonId,
          response?.data?.data?._id
        );
      }, 1);

      return response.data;
    });
};

export const logout = () => {
  return axiosInstance()
    .post(APIS.LOGOUT, { deviceId: "321" })
    .then((response) => response.data);
};
