import React from "react";
import "./index.css";
import { IMAGES } from "constants/assets";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { APIS } from "api";
import { toast } from "react-toastify";
import ToastMessage from "components/layout/toast";
import { TOAST_MESSAGE } from "constants/toastMessage";
import { addEditApi } from "api/commonApis";

const DeleteOrg = ({ id, org_name }) => {
  const [show_delete_diag, set_show_delete_diag] = useState(false);
  const [loading, set_loading] = useState(false);

  const [deletion_field, set_deletion_field] = useState();

  const navigate = useNavigate();

  const delete_org = () => {
    set_loading(true);
    addEditApi(APIS.DELETE_DOCUMENT_DATA, { type: 3, id: id }).then(
      (result) => {
        toast.success(
          <ToastMessage
            title={"Success"}
            message={TOAST_MESSAGE.DELETE_ORGANISATION}
          />
        );
        set_loading(false);
        set_show_delete_diag(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    );
  };

  return (
    <div>
      <button
        onClick={() => {
          set_show_delete_diag(true);
        }}
        className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-transparent hover:tw-bg-slate-100 "
      >
        <img className="tw-w-3 tw-h-3" src={IMAGES.RI_DELETE_BIN} alt="edit" />
      </button>

      {show_delete_diag &&
        createPortal(
          <div className="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-screen tw-z-50 tw-bg-black/50 tw-flex tw-items-center tw-justify-center">
            <div className="tw-rounded-md tw-p-8 tw-w-5/12 tw-bg-white">
              <div className="tw-bg-red-50/50 tw-border-l-8 tw-border-[#bf1736] tw-rounded-md tw-p-4 tw-text-[#bf1736]">
                <p className="tw-flex tw-space-x-2 tw-items-center tw-text-[#bf1736] tw-mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="tw-w-4 tw-h-4 tw-flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                    />
                  </svg>
                  <span className="tw-font-bold">Alert</span>
                </p>
                <p className="tw-text-[#bf1736]">
                  You are about to delete <b>{org_name}</b>. This will delete
                  all data associated with this organisation such as billing,
                  contracts, invoices, its team members and their content.
                  Additionally, it will unset the organization from any existing
                  or archive assignment(s). Please note that this action is{" "}
                  <b>non-reversible</b>.
                </p>
              </div>

              <div className="tw-my-6">
                <p className="tw-text-black tw-text-base !tw-m-0 tw-pb-2">
                  Type DELETE to confirm deletion
                </p>
                <input
                  onInput={(e) => {
                    set_deletion_field(e.target.value);
                  }}
                  type="text"
                  className="tw-p-2.5 tw-w-full tw-outline-none tw-bg-[#f4f4f4] tw-rounded-md"
                />
              </div>

              <div className="tw-flex tw-justify-end tw-space-x-4">
                <button
                  className="tw-px-4 tw-text-xs hover:tw-bg-black hover:tw-text-white tw-py-2 tw-rounded-md tw-bg-slate-100"
                  onClick={() => {
                    set_show_delete_diag(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={deletion_field !== "DELETE"}
                  className="tw-px-4 tw-min-w-[170px] tw-text-xs disabled:tw-bg-slate-600/50 disabled:hover:tw-border-transparent disabled:tw-cursor-not-allowed disabled:hover:tw-text-white hover:tw-border-[#bf1736] hover:tw-bg-transparent hover:tw-text-[#bf1736] tw-border tw-border-transparent tw-text-white tw-py-2 tw-rounded-md tw-bg-[#bf1736]"
                  onClick={() => {
                    delete_org();
                  }}
                >
                  {loading ? (
                    <svg
                      class="tw-animate-spin tw-h-5 tw-w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Delete Organization"
                  )}
                </button>
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default DeleteOrg;
