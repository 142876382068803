import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./styles.module.scss";
import { addEditApi, listApi } from "../../api/commonApis";
import { APIS } from "../../api/endpoints";
import { useNavigate, useSearchParams } from "react-router-dom";
import AssignmentRow from "../../components/table/assignmentRow";
import Skeleton from "../../components/skeleton";
import Pagination from "../../components/table/pagination";
import SearchBar from "../../components/searchBar";
import DefaultSorting from "../../components/filters/defaultsorting";
import BasicConfirmationModal from "../../components/modal/basicConfirmationModal";
import { ROUTES } from "../../router/routes";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import {
  AssAndOrgSortingOptions,
  assignmentFilter,
  NO_DATA_FOUND_LIST,
} from "../../constants/contants";
import CapsuleFilter from "../../components/filters/capsuleFilter";
import ScrollIcon from "../../components/icons/scrollIcon";
import SuggestionDropDown from "../../components/suggestionDropDown";
import CommonFilter from "components/commonFilter";
import { hasPermission } from "utils/helper";
import { MODULES, SUB_MODULES, PERMISSIONS } from "constants/accessControl";
import { sidePanelAccess } from "utils/helper";
import NoListData from "components/table/noListData";
import { getState } from "redux/store";

const defaultFilters = {
  company: [],
  consultant: [],
  director: [],
  locations: [],
};

const Assignments = () => {
  const [params, setParams] = useSearchParams();
  const activetab = params?.get?.("activetab");
  const [assignmentsData, setAssignmentsData] = useState([]);
  const [count, setCount] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState(1);
  const [statusFilter, setStatusFilter] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(0);
  const [assignmentId, setAssignmentId] = useState();
  const [appliedFilters, setAppliedFilters] = useState(defaultFilters);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [filterHasValue, setFilterHasValue] = useState(false);
  const [onScroll, setOnScroll] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestionData, setSuggestionData] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [hasAssWriteAccess, setHasAssWriteAccess] = useState(false);
  const [isSidePanel, setIsSidePanel] = useState(false);

  ////// PERMISSION/////

  useEffect(() => {
    setHasAssWriteAccess(
      hasPermission(
        MODULES.ASSIGNMENT,
        SUB_MODULES.ASSIGNMENT_OVERVIEW,
        PERMISSIONS.WRITE
      )
    );
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropDown(false);
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setOpenDropDown(true);
      document.addEventListener("click", handleClickOutside, true);
    }
    if (search === "") {
      setOpenDropDown(false);
      document.addEventListener("click", handleClickOutside, true);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [search]);

  ///// To check whether  any filter is applied or not///////
  useEffect(() => {
    const cloneFilterData = cloneDeep(appliedFilters);
    setFilterHasValue(false);
    if (cloneFilterData.activeAssignments) {
      setFilterHasValue(true);
    }
    if (cloneFilterData.relocate) {
      setFilterHasValue(true);
    }
    delete cloneFilterData.activeAssignments;
    delete cloneFilterData.relocate;
    Object.keys(cloneFilterData).map((items) => {
      if (cloneFilterData[items].length > 0) {
        setFilterHasValue(true);
      }
    });
  }, [appliedFilters]);

  /*Reset the Page to First When Any FIlter is Applied */
  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [appliedFilters]);

  useEffect(() => {
    setLoading(true);
    let filterData = {};
    let type;

    if (appliedFilters?.company?.length > 0) {
      filterData.clients = JSON.stringify(appliedFilters?.company);
    }
    if (appliedFilters?.locations?.length > 0) {
      filterData.locations = JSON.stringify(appliedFilters?.locations);
    }
    if (appliedFilters?.director?.length > 0) {
      filterData.director = JSON.stringify(appliedFilters?.director);
    }
    if (appliedFilters?.consultant?.length > 0) {
      filterData.consultant = JSON.stringify(appliedFilters?.consultant);
    }
    if (activetab === "open-assignments") {
      type = 1;
    }
    if (activetab === "archived-assignments") {
      type = 2;
    }

    const payload = {
      search,
      skip: limitPerPage * page,
      limit: limitPerPage,
      type: type,
      ...filterData,
    };
    if (statusFilter.length > 0) {
      payload.status = JSON.stringify(statusFilter);
    }
    if (sorting) {
      payload.sort = sorting;
    }

    listApi(APIS.LIST_ASSIGNMENT, payload)
      .then((result) => {
        setAssignmentsData(result?.data?.data);
        setCount(result?.data?.count);
      })
      .finally(() => setLoading(false));
  }, [
    searchClicked,
    activetab,
    statusFilter,
    sorting,
    page,
    limitPerPage,
    appliedFilters,
    refresh,
  ]);

  /*suggestion API */

  useEffect(() => {
    listApi(APIS.SEARCH_SUGGESTION, {
      search: search ? search : "",
      type: 3,
    }).then((result) => {
      setSuggestionData(result?.data);
    });
  }, [search]);

  const SearchClick = () => {
    setSearchClicked((prev) => !prev);
  };

  const onPageChange = (pageNo) => {
    setPage(pageNo);
  };

  const searchHandler = (e) => {
    e?.preventDefault();
    setSearch(e.target[0].value);
  };

  const DefaultSortingHandler = (e) => {
    setSorting(e.value);
  };

  const selectFilter = (value) => {
    if (statusFilter.includes(value)) {
      setStatusFilter((current) =>
        current.filter((data) => {
          return data !== value;
        })
      );
    } else {
      setStatusFilter((prevState) => [...prevState, value]);
    }
    setPage(0);
  };

  /* Click on clone Option in menu */
  /* And Open Confirmation modal */

  const cloneOptionClickHanlder = (assignmentId) => {
    setAssignmentId(assignmentId);
    setOpenConfirmation(true);
  };

  /* Close confirmation Handler */

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  /* Clone APi Hanlder  */

  const cloneConfirmHandler = () => {
    handleCloseConfirmation();

    addEditApi(APIS.CLONE_ASSIGNMENT, { assignmentId: assignmentId }).then(
      (response) => {
        setRefresh(!refresh);
        window.open(
          `${ROUTES.ASSIGNMENTS}${ROUTES.OVERVIEW}/${response?.data?._id}?tab=overview&activetab=${activetab}`,
          "_blank"
        );
      }
    );
  };

  const searchTypeHandler = (e) => {
    if (e.target.value === "") {
      setSearch("");
      SearchClick();
    }
    setSearch(e.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(searchTypeHandler, 300),
    []
  );

  useEffect(() => {
    const innerWrapper = document.querySelector("#innerwrapper");
    if (innerWrapper?.clientWidth < innerWrapper?.scrollWidth) {
      setOnScroll(true);
    }
  });

  /////// HANLDER FOR CHEKING IS THER SIDE PANEL OR NOT//////

  useEffect(() => {
    setIsSidePanel(sidePanelAccess());
  }, []);

  // check if user is authorized to access this route
  const navigate = useNavigate();
  useEffect(() => {
    const current_user = getState()?.auth?.data;

    if (
      current_user.talentLabRole === "Organizations" ||
      current_user.talentLabRole === "Talent"
    ) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <section
        className={`${styles.hasStyles} ${
          isSidePanel ? styles.overview : styles.centerOverview
        }`}
      >
        <div className="container">
          <SearchBar
            appliedFilters={appliedFilters}
            placeholder="assignments"
            onSubmit={searchHandler}
            onChange={debouncedChangeHandler}
            onFilterClick={() => setShowFilters((prev) => !prev)}
            setInputValue={setInputValue}
            inputValue={inputValue}
            SearchClick={SearchClick}
            resetValue={resetValue}
            setResetValue={setResetValue}
          />
          {openDropDown && (
            <div ref={dropdownRef}>
              <SuggestionDropDown
                dropdownData={suggestionData}
                key="assignment"
                onClick={(data) => {
                  setOpenDropDown(false);
                  window.open(
                    `${ROUTES.ASSIGNMENTS}${ROUTES.OVERVIEW}/${data?._id}?tab=overview&activetab=${activetab}`,
                    "_blank"
                  );
                }}
              />
            </div>
          )}

          {showFilters && (
            <CommonFilter
              appliedFilters={appliedFilters}
              onChange={setAppliedFilters}
              isApplied={filterHasValue}
              onReset={() => setAppliedFilters(defaultFilters)}
              type={2}
            />
          )}

          <div
            style={{ visibility: onScroll ? "visible" : "hidden" }}
            className={styles.scrollConatiner}
          >
            <ScrollIcon />
          </div>
          <div className="mt-3 row">
            <div className="col-md-12">
              <div
                className={`d-flex align-items-center justify-content-between ${styles.showingresult}`}
              >
                <div
                  className={`d-flex align-items-center ${styles.showingleft}`}
                >
                  <p className={`mb-0 ${styles.resultStyles}`}>
                    Showing <strong> {count} </strong> results
                  </p>
                  <div
                    onScroll={() => setOnScroll(true)}
                    id="innerwrapper"
                    className={styles.capusleContainer}
                  >
                    {assignmentFilter?.map((filter, i) => (
                      <CapsuleFilter
                        key={i}
                        label={filter?.label}
                        isActive={statusFilter.includes(filter?.value)}
                        onClick={() => selectFilter(filter?.value)}
                      />
                    ))}
                  </div>
                  <div
                    className={`d-flex align-items-center ${styles.showingright}`}
                  >
                    <DefaultSorting
                      fontSize="12px"
                      type="select"
                      value={AssAndOrgSortingOptions.filter(
                        (i) => i.value === sorting
                      )}
                      onChange={DefaultSortingHandler}
                      options={AssAndOrgSortingOptions}
                      placeholder={"Default Sorting"}
                      name={"sorting"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 row">
            {loading ? (
              Array.from(Array(limitPerPage).keys())?.map((i, index) => (
                <Skeleton height="65px" key={index} marginBottom="15px" />
              ))
            ) : (
              <div className="col-md-12">
                {assignmentsData.length === 0 ? (
                  <NoListData label={NO_DATA_FOUND_LIST.ASSIGNMENT_LIST} />
                ) : (
                  assignmentsData?.map((assignmentsData, index) => {
                    return (
                      <AssignmentRow
                        hasAssWriteAccess={hasAssWriteAccess}
                        key={index}
                        data={assignmentsData}
                        activetab={activetab}
                        cloneOptionClickHanlder={cloneOptionClickHanlder}
                      />
                    );
                  })
                )}
              </div>
            )}
            {assignmentsData?.length !== 0 && (
              <Pagination
                totalCount={count}
                currentPage={page}
                limitPerPage={limitPerPage}
                onPageChange={onPageChange}
                setLimitPerPage={setLimitPerPage}
              />
            )}

            <BasicConfirmationModal
              openConfirmation={openConfirmation}
              closeModal={handleCloseConfirmation}
              cloneConfirmHandler={cloneConfirmHandler}
              data="Are you sure want to clone this assignment?"
              buttonLabel="Clone"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Assignments;
