import { ROLES } from "constants/contants";
import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useRoutes } from "react-router-dom";
import Header from "../components/layout/header";
import Sidebar from "../components/layout/sidebar";
import { ROUTES } from "./routes";

export const PrivateLayout = () => {
  const isLoggedIn = useSelector((state) => state?.auth?.data);
  const userRole = useSelector((state) => state?.auth?.data?.talentLabRole);
  const userLoginType = useSelector((state) => state?.auth?.data?.type);
  const [loginRoute, setLoginRoute] = useState(ROUTES.LOGIN);

  useEffect(() => {
    if (userLoginType === 2) {
      setLoginRoute(ROUTES.TALENT_LOGIN);
    } else if (userLoginType === 3) {
      setLoginRoute(ROUTES.CLIENT_LOGIN);
    }
  }, [userLoginType]);

  const showMenu =
    userRole !== ROLES.TALENT && userRole !== ROLES.ORGANIZATIONS;

  return isLoggedIn ? (
    <Fragment>
      <Header showMenu={showMenu} />
      <div className="main-wraper tw-relative">
        <Sidebar />
        <Outlet />
        <div className="tw-fixed tw-px-8 tw-py-4 tw-bottom-0 tw-left-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-slate-400 tw-mt-4">
          <a
            href="/terms-and-condition"
            target="_blank"
            className="tw-pb-0.25 tw-border-b tw-border-current tw-text-[10px] tw-text-slate-400 "
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>

          <a
            href="/privacy-policy"
            target="_blank"
            className="tw-pb-0.25 tw-mt-2 tw-border-b tw-border-current tw-text-[10px] tw-text-slate-400 "
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </Fragment>
  ) : (
    <Navigate to={loginRoute} />
  );
};

export const PublicLayout = () => {
  const isLoggedIn = useSelector((state) => state?.auth?.data);
  const userRole = useSelector((state) => state?.auth?.data?.talentLabRole);

  const getDashboardRoute = () => {
    if (userRole === ROLES.TALENT) {
      return `${ROUTES.TALENT}/overview/${isLoggedIn?.commonId}?tab=overview`;
    } else if (userRole === ROLES.ORGANIZATIONS) {
      return `${ROUTES.CLIENTS}/overview/${isLoggedIn?.commonId}?tab=overview`;
    }
    return ROUTES.OVERVIEW;
  };

  return isLoggedIn ? <Navigate to={getDashboardRoute()} /> : <Outlet />;
};
