import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import Skeleton from "../../../../components/skeleton";
import { emptyTableText } from "../../../../constants/contants";
import {
  MODULES,
  SUB_MODULES,
  PERMISSIONS,
} from "../../../../constants/accessControl";
import ScrollIcon from "../../../../components/icons/scrollIcon";
import OutLineButton from "components/buttons/outLineButton";
import ClientHeader from "components/pageHeader/clientHeader";
import ContactTable from "./contactTable";
import { IMAGES } from "constants/assets";
import { hasPermission } from "utils/helper";

const PointOfContacts = ({ client }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [onScroll, setOnScroll] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const [hasWriteAccess, setHasWriteAccess] = useState(false);

  useEffect(() => {
    setHasWriteAccess(
      hasPermission(
        MODULES.ORGANIZATIONS,
        SUB_MODULES.POINT_OF_CONTACTS,
        PERMISSIONS.WRITE
      )
    );
  }, []);

  return (
    <div className={styles.talentdetails}>
      {!client ? (
        <Skeleton height="300px" marginBottom="30px" />
      ) : (
        <>
          <ClientHeader
            client={client}
            hasWriteAccess={hasWriteAccess}
            id={id}
            currentLocation={window.location.href}
          />

          <div className={styles.tableContainer}>
            <div className={`mb-3 pb-1 ${styles.summary}`}>
              <div className={styles.tableTopContainer}>
                <h3 className={styles.summaryHead}>Point of Contacts</h3>
                {onScroll && <ScrollIcon />}
              </div>
              {client?.contactPersons?.length &&
              client?.contactPersons?.[0]?.name ? (
                <div
                  onScroll={() => setOnScroll(true)}
                  className={styles.scrollContainer}
                >
                  <ContactTable client={client} />
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <h4 className={styles.dataNotFound}>
                    {emptyTableText.contactPersons}
                  </h4>
                </div>
              )}
              <OutLineButton
                visible={hasWriteAccess}
                label="Add Point Of Contact"
                icon={onHover ? IMAGES.ACTIVE_PLUS_BUTTON : IMAGES.NEW_ADDITION}
                onMouseEnter={(e) => {
                  setOnHover(true);
                }}
                onMouseLeave={(e) => {
                  setOnHover(false);
                }}
                onClick={() => {
                  navigate(
                    `/clients/detail/${id}?source=overview&linkto=contactform`
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default PointOfContacts;
